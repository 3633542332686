<template>
  <div class="main-widget-geogrid-tabs">
    <div class='btn-group-justified m-b-1' role='group'>
      <a
        v-for="tab in tabs[pageState]"
        :class="[
          'btn btn-secondary radio-btn',
          { active: pageTab === tab.val, 'pseudo-disabled': disabledTab(tab.val)}
        ]"
        :id="`geogrids-tab-${pageState}-${tab.val}`"
        @click="disabledTab(tab.val) ? (() => {}) : goTo(tab.val)">
        {{ tab.title }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Tabs',
  data() {
    return {
      tabs: {
        live: [
          { val: 'new', title: 'New geogrid' },
          { val: 'index', title: 'History' },
          { val: 'show', title: 'Geogrid details' }
        ],
        scheduled: [
          { val: 'new', title: 'New config' },
          { val: 'index', title: 'Configs' },
          { val: 'show', title: "Config's geogrids" },
          { val: 'edit', title: 'Edit config' }
        ]
      },
      isEditingDisabled: !Styxie?.USER?.editor
    }
  },
  computed: mapState(['pageState', 'pageTab', 'geogrid', 'config']),
  methods: {
    disabledTab(val) {
      if (this.isEditingDisabled) return val === 'new' || val === 'edit'
      return (this.pageTab === 'index' || this.pageTab === 'new') && (val === 'show' || val === 'edit')
    },
    ...mapActions(['goTo'])
  }
}
</script>
