import Vuex from 'vuex'
import axios from 'axios'
import toastr from 'toastr'
import axiosTransform from 'common/axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

const { CancelToken } = axios
let cancel

export default function createStore(prms) {
  return new Vuex.Store({
    state: {
      page: 0,
      perPage: 40,
      allFiles: 0,
      imagesCount: null,
      order: 'desc',
      images: [],
      loading: false,
      selectedImages: [],
      queuededFiles: 0,
      uploadedFiles: 0,
      totalLoading: 0,
      isShiftPressed: false,
      cursorImage: 0,
      shiftCursorImage: null,
      showMoreLoading: false,
      selectedTags: [],
      showScheduledPanel: false,
      showExportPanel: false,
      ...prms
    },
    getters: {
      isSelected: (state) => (id) => state.selectedImages.includes(id),
      showPagination: (state) => (state.perPage * state.page < state.allFiles),
      getSelectedTagsCount: (state) => state.selectedTags.length,
      selectedImageObjs: (state) => state.images.filter((image) => state.selectedImages.includes(image.id))
    },
    mutations: {
      setPage(state, val) {
        state.page = val
      },
      setImages(state, arr) {
        if (state.page === 1) {
          state.images = arr
        } else {
          state.images = [...state.images, ...arr]
        }
      },
      addImages(state, arr) {
        state.images = [...arr, ...state.images]
      },
      updateFileStates(state, { fileId, props }) {
        const fileIndex = state.images.findIndex((img) => img.id === fileId)
        Object.keys(props).forEach((key) => {
          state.images[fileIndex][key] = props[key]
        })
      },
      setAllfiles(state, val) {
        state.allFiles = val
      },
      setImagesCount(state, val) {
        state.imagesCount = val
      },
      removeImages(state) {
        state.images = state.images.filter((value) => !state.selectedImages.includes(value.id))
        state.selectedImages = []
      },
      setLoading(state, val) {
        state.loading = val
      },
      setTotalLoading(state, val) {
        state.totalLoading += val
      },
      resetTotalLoading(state) {
        state.totalLoading = 0
      },
      setQueuededFiles(state, val) {
        if (val) {
          state.queuededFiles++
        } else {
          state.queuededFiles = 0
        }
      },
      setUploadedFiles(state, val) {
        if (val) {
          state.uploadedFiles++
        } else {
          state.uploadedFiles = 0
        }
      },
      selectImage(state, id) {
        if (!id) return

        const imageIndex = state.images.findIndex((img) => img.id === id)
        if (state.isShiftPressed) {
          if (state.shiftCursorImage !== null) {
            const startIndex = Math.min(state.shiftCursorImage, state.cursorImage)
            const endIndex = Math.max(state.shiftCursorImage, state.cursorImage)

            for (let i = startIndex; i <= endIndex; i++) {
              if (state.selectedImages.indexOf(state.images[i].id) !== -1) {
                state.selectedImages.splice(state.selectedImages.indexOf(state.images[i].id), 1)
              }
            }
          }

          state.shiftCursorImage = imageIndex
          const startIndex = Math.min(imageIndex, state.cursorImage)
          const endIndex = Math.max(imageIndex, state.cursorImage)

          for (let i = startIndex; i <= endIndex; i++) {
            if (state.selectedImages.indexOf(state.images[i].id) === -1) {
              state.selectedImages.push(state.images[i].id)
            }
          }
        } else {
          state.cursorImage = imageIndex
          if (state.selectedImages.indexOf(id) === -1) {
            state.selectedImages.push(id)
          } else {
            state.selectedImages.splice(state.selectedImages.indexOf(id), 1)
          }
        }
      },
      selectAll(state, val) {
        if (val) {
          state.images.forEach((value) => {
            if (!this.getters.isSelected(value.id)) {
              state.selectedImages.push(value.id)
            }
          })
        } else {
          state.selectedImages = []
        }
      },
      shiftPressed(state, val) {
        state.isShiftPressed = val
        if (!val) {
          state.shiftCursorImage = null
        }
      },
      setShowMoreLoading(state, val) {
        state.showMoreLoading = val
      },
      selectTag(state, val) {
        if (state.selectedTags.includes(val)) {
          state.selectedTags.splice(state.selectedTags.indexOf(val), 1)
        } else {
          state.selectedTags.push(val)
        }
      },
      unselectAllTags(state) {
        state.selectedTags = []
      },
      setTags(state, tags) {
        state.imageTags = tags
        state.selectedTags = state.selectedTags.filter((selectedTag) => tags.includes(selectedTag))
      },
      toggleScheduledPannel(state) {
        state.showScheduledPanel = !state.showScheduledPanel
      },
      toggleExportPannel(state) {
        state.showExportPanel = !state.showExportPanel
      },
      setOrder(state, val) {
        state.order = val
      }
    },
    actions: {
      async fetchImages({ state, commit }) {
        if (cancel !== undefined) {
          cancel()
        }
        commit('setShowMoreLoading', true)
        await axios.get(state.apiImagesPath, {
          params: {
            page: state.page + 1,
            per: state.perPage,
            tagged_with: state.selectedTags,
            order: state.order
          },
          cancelToken: new CancelToken((c) => {
            cancel = c
          })
        })
          .then((res) => {
            commit('setShowMoreLoading', false)
            commit('setPage', state.page + 1)
            if (state.page === 1) {
              commit('selectAll', false)
            }
            commit('setAllfiles', parseInt(res.headers['x-total-count'], 10))
            commit('setImagesCount', parseInt(res.headers['x-total-unscoped-count'], 10))
            commit('setImages', res.data)
            return true
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              toastr.error('Server error')
            }
            return false
          })
      },
      async unselectAllTags({ commit, dispatch }) {
        commit('unselectAllTags')
        commit('setPage', 0)
        await dispatch('fetchImages')
      },
      removeSelected({ state, commit, dispatch }) {
        axios
          .delete(`${state.apiImagesPath}/bulk_delete`, {
            params: {
              ids: state.selectedImages
            }
          })
          .then((res) => {
            commit('removeImages')
            commit('setImagesCount', parseInt(res.headers['x-total-unscoped-count'], 10))
            if (state.imagesCount !== 0 && state.images.length === 0) {
              commit('setPage', 0)
              dispatch('fetchImages')
            }
          })
          .catch((error) => {
            if (error?.response?.status === 403) return
            toastr.error(DEFAULT_ERROR_MESSAGE)
          })
      },
      addFiles({ dispatch, commit }, arr) {
        const newArr = arr.map((file) => {
          if (file.type === 'image/png' || file.type === 'image/jpeg') {
            file.dataURL = URL.createObjectURL(file)
            dispatch('uploadFile', file)
            return {
              id: file.id,
              dataURL: file.dataURL,
              tags: []
            }
          }

          toastr.error('Not supported file format')
          return {
            id: file.id,
            dataURL: file.dataURL,
            status: 'error',
            tags: [],
            error: {
              mgs: 'Not supported file format'
            }
          }
        })

        commit('addImages', newArr)
      },
      uploadFile({ state, commit }, file) {
        commit('setQueuededFiles', true)

        const config = {
          onUploadProgress(progressEvent) {
            const completed = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            file.status = 'uploading'

            if (file.progress) {
              commit('setTotalLoading', completed - file.progress)
            } else {
              commit('setTotalLoading', completed)
            }

            file.progress = completed
            commit('setLoading', true)
          }
        }

        const data = new FormData()
        data.append('file', file)

        axios
          .post(state.apiImagesPath, data, config)
          .then((resp) => {
            commit('updateFileStates', {
              fileId: file.id,
              props: {
                id: resp.data.id,
                status: 'success'
              }
            })
            commit('setUploadedFiles', true)
            commit('setImagesCount', state.imagesCount !== null ? 1 : state.imagesCount + 1)
            if (state.queuededFiles === state.uploadedFiles) {
              setTimeout(() => {
                commit('setLoading', false)
                commit('setQueuededFiles', false)
                commit('setUploadedFiles', false)
                commit('resetTotalLoading')
              }, 2000)
            }
          })
          .catch(() => {
            file.status = 'error'
            setTimeout(() => {
              commit('setLoading', false)
            }, 2000)
            toastr.error('Server error')
          })
      },
      selectTag({ dispatch, commit }, val) {
        commit('selectTag', val)
        commit('setPage', 0)
        dispatch('fetchImages')
      },
      updateTags({ state }, tags) {
        axios
          .post(`${state.apiImagesPath}/update_tags`, {
            ids: state.selectedImages,
            ...tags
          }, axiosTransform)
          .then(() => {
            toastr.success('Saving tags...')
          })
          .catch((error) => {
            if (error?.response?.status === 403) return
            toastr.error('Server error. Can\'t update tags')
          })
      }
    }
  })
}
