<template>
  <div v-if="show" class="review-modal fade" :class="{'show': show}">
    <div class="review-modal__dialog">
      <div class="review-modal__content">
        <div class="review-modal__header">
          <template v-if="!review">
            <i class="fa-solid fa-message-question fa-3x text-warning" />
            <h3> Review not found </h3>
          </template>
          <template v-else-if="isReplyApproved">
            <i class="fa-solid fa-message-dots fa-3x color-green" />
            <h3> Reply has been already published </h3>
          </template>
          <template v-else>
            <i class="fa-solid fa-circle-check fa-3x color-green" />
            <h3> Reply is approved and submitted to Google! </h3>
          </template>
        </div>

        <div class="review-modal__body">
          <template v-if="!review">
            <p>We apologize, but something went wrong, and no reviews were found.</p>
          </template>
          <template v-else>
            <div class="card-review-dashboard">
              <div class="card-review-dashboard__top">
                <div class="card-review-dashboard__business">
                  <i class="fa-regular fa-briefcase mr-1" />
                  <a :href="review.linkToOrgloc" target="_blank">{{ review.locationName }}</a>
                </div>
                <div
                  v-if="review.locationStoreCode"
                  class="card-review-dashboard__store-code">
                  Store Code: {{ review.locationStoreCode }}
                </div>
              </div>
              <div class="card-review-dashboard__wrapper">
                <div class="card-review-dashboard__author">
                  <div v-if="review.reviewer?.profilePhotoUrl" class="card-review-dashboard__avatar">
                    <img :src="review.reviewer?.profilePhotoUrl" :alt="`${review.reviewer.displayName} avatar`">
                  </div>
                  <div v-else class="card-review-dashboard__avatar">
                    <span>
                      {{ review.reviewer.displayName[0] }}
                    </span>
                  </div>
                  <span>{{ review.reviewer.displayName }}</span>
                </div>
                <div class="card-review-dashboard__body">
                  <div class="card-review-dashboard__rating">
                    <i v-for="i in 5" :key="i"
                      class="fa-solid fa-star"
                      :class="{ 'active': i <= review.starRating }" />
                    <span class="card-review-dashboard__date">{{ review.remoteCreatedAt }}</span>
                  </div>
                  <p>{{ formatCommentText(review?.comment) }}</p>
                </div>
              </div>
              <div v-if="review.reviewReply" class="card-review-dashboard__bottom">
                <div class="card-review-dashboard__bottom-left">Reply:</div>
                <div class="card-review-dashboard__response">
                  <span>{{ review.reviewReply.createdAt }}</span>
                  <p>{{ formatCommentText(review.reviewReply?.comment) }}</p>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="review-modal__footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReviewModal',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    review: {
      type: Object,
      required: false
    },
    isReplyApproved: {
      type: Boolean,
      required: true
    }
  },
  created() {
    this.translationFormatRegexp = /(\(Translated by Google\)|\(Original\))/g
  },
  methods: {
    formatCommentText(comment) {
      if (!comment) return ''
      const formatted = comment.replace(this.translationFormatRegexp, '\n$1:\n').trim()
      return formatted.replace(/\n{2,}/g, '\n')
    },
    closeModal() {
      const url = new URL(window.location.href)
      url.searchParams.delete('approve_reply')
      window.history.replaceState({}, '', url.toString())
      this.$emit('updateShow', false)
    }
  }
}
</script>
