<template>
  <div class="d-flex align-items-center justify-content-between1 flex-row p-3 gap-5">
    <div class="w-50">
      <div class="form-field mb-2">
        <label class="mb-1">
          Rating<span class="text-danger">*</span>
        </label>

        <VueMultiselect
          v-model="internalReview.freeFormStarRating"
          :options="Object.keys(STARS_OPTIONS)"
          :max-height="540"
          :searchable="false"
          :allow-empty="false"
          placeholder="Select rating">
          <template slot="option" slot-scope="{ option }">{{ STARS_OPTIONS[option] }}</template>
          <template slot="singleLabel" slot-scope="{ option }">{{ STARS_OPTIONS[option] }}</template>
        </VueMultiselect>
      </div>

      <div class="form-field mb-2">
        <label class="mb-1">
          Reviewer<span class="text-danger">*</span>
        </label>
        <input v-model="internalReview.freeFormReviewer" type="text" placeholder="Reviewer's name">
      </div>

      <div class="form-field mb-2">
        <label class="mb-1">
          Comment<span class="text-danger">*</span>
        </label>
        <textarea v-model="internalReview.freeFormComment" placeholder="Review" />
      </div>

      <div class="form-field mb-2">
        <label class="mb-1">
          Reply
        </label>
        <textarea v-model="internalReview.freeFormReply" placeholder="Reply" />
      </div>

      <div class="form-field mb-2">
        <label class="mb-1">
          Platform Name<span class="text-danger">*</span>
        </label>
        <input v-model="internalReview.freeFormPlatform" type="text" placeholder="Review platform name">
      </div>

      <div class="form-field mb-2">
        <label class="mb-1">
          Review Link<span class="text-danger">*</span>
        </label>
        <input v-model="internalReview.freeFormLink" type="text" placeholder="Review link">
      </div>
    </div>

    <CardReview v-if="verifyInternalReview" :review="gmbReviewFormat" class="moment-reviews__item w-25" />
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'

import CardReview from './card_review'

const DEFAULT_REVIEW = {
  rating: 'five',
  reviewer: '',
  comment: '',
  reviewReply: '',
  freeFormPlatform: '',
  freeFormLink: ''
}

export default {
  components: {
    VueMultiselect,
    CardReview
  },
  props: {
    review: { type: Object }
  },
  data() {
    const review = this.review || { ...DEFAULT_REVIEW }
    return {
      internalReview: {
        freeFormStarRating: review.rating,
        freeFormReviewer: review.reviewer,
        freeFormComment: review.comment,
        freeFormReply: review.reviewReply,
        freeFormPlatform: review.freeFormPlatform,
        freeFormLink: review.freeFormLink
      }
    }
  },
  computed: {
    verifyInternalReview() {
      return this.internalReview.freeFormStarRating.length &&
        this.internalReview.freeFormReviewer.length &&
        this.internalReview.freeFormComment.length
    },
    gmbReviewFormat() {
      const formatted = {
        rating: this.STARS_OPTIONS[this.internalReview.freeFormStarRating],
        reviewer: { displayName: this.internalReview.freeFormReviewer },
        comment: this.internalReview.freeFormComment
      }

      if (this.internalReview.freeFormReply.length) {
        formatted.reviewReply = { comment: this.internalReview.freeFormReply }
      }

      return formatted
    }
  },
  created() {
    this.STARS_OPTIONS = {
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      five: 5
    }
  },
  mounted() {
    this.$emit('selectFreeFormReview', { ...this.internalReview })
  },
  watch: {
    internalReview: {
      handler(newVal) {
        this.$emit('selectFreeFormReview', { ...newVal })
      },
      deep: true
    }
  }
}
</script>
