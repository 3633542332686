<template>
  <li class="question" :class="{ active: isActive(question.remote_id), removed: (question.remote_deleted_at) }"
    @click.prevent="switchQuestion(question)" :title="(question.remote_deleted_at) && 'Removed by Google'">
    <div class="question-header">
      <img class="rounded-circle mr-2" height="30" width="30"
        alt="avatar"
        :src="question.author.profile_photo_url || require('no_data/avatar.png')">

      <div class="question-author">
        <strong>{{ question.author.display_name }}</strong>
        <small>
          <template v-if="question.remote_lost_at">
            <i class="far fa-info-circle small" :id="`question_${question.remote_id}`" />
            <b-popover :target="`question_${question.remote_id}`" triggers="hover" placement="top">
              This question has probably been removed from Google.
            </b-popover>
          </template>

          {{ question.remote_created_at }} ({{ question.answers.length }} {{ 'answer' | pluralize(question.answers.length) }})
        </small>
      </div>

      <div v-if="showDelete && question.author.type === 'MERCHANT' && !question.remote_deleted_at" class="question-actions">
        <a href="#"
          :class="['btn btn-link', { disabled: !apiConnection || isEditingDisabled }]"
          @click.prevent="onDeleteQuestion(question)">
          <i class="icon far fa-trash" />
        </a>
      </div>
    </div>

    <div class="question-body">
      {{ question.text }}
    </div>
  </li>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { runSwal } from 'common/delete_with_swal'

export default {
  props: {
    question: Object,
    showDelete: Boolean
  },
  created() {
    this.isEditingDisabled = !Styxie?.USER?.editor
  },
  computed: mapState(['currentQuestion', 'apiConnection']),
  methods: {
    ...mapActions(['switchQuestion', 'deleteQuestion']),
    isActive(id) {
      if (!this.currentQuestion) return false
      return id === this.currentQuestion.remote_id
    },
    onDeleteQuestion(question) {
      runSwal(() => {
        const $el = $(this.$el).toggleWrapper()
        this.deleteQuestion({
          question: question,
          cleanWrapperCb: () => {
            $el.toggleWrapper({}, false)
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .question {
    background: #efefef;
    border-radius: 3px;
    border: 2px solid transparent;
    list-style: none;
    margin-bottom: 10px;
    padding: 10px;

    &-header {
      display: flex;
    }

    &-body {
      margin-top: 7px;
    }

    &-author {
      display: flex;
      flex-direction: column;
      flex: 1;
      line-height: 15px;
    }

    &-actions {
      font-size: 20px;
    }
  }
</style>
