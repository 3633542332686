/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// images
import 'geogrid-example.png'
import 'viking-dtm.jpg'
import 'gmb-management-hero.png'
import 'gmb-management.svg'
import 'gmb-management.png'
import 'gmblogo.png'
import 'manage-comp.svg'
import 'post-scheduling-new.svg'
import 'post-scheduling-new.png'
import 'rank-tracking.svg'
import 'rank-tracking.png'
import 'svg-pointer1.svg'
import 'us.svg'
import 'viking-carry.png'
import 'viking-sou.png'
import 'viking-hero-main.png'
import 'viking-hero-main.svg'
import 'viking-sit.png'
import 'whitelogo.png'
import 'viking-seo-toolbox.png'
import 'google-icon.svg'
import 'vikingknight.jpg'
import 'repco-logo-transparent.png'
import 'gb-power.png'
import 'gb-power@2x.png'

import 'stylesheets/application'

import 'moment'
import 'bootstrap'
import 'select2'

import 'common/rails'
import 'common/jquery_plugins/toggle_wrapper'
import 'common/jquery_plugins/ajax_with_csrf_token'
import 'common/jquery_plugins/turbolinks/turboselect'
import 'common/jquery_plugins/turbolinks/turbotable'
import 'common/highcharts/sparkline'
import 'common/stripe_form'
import 'vendor/styxie'

import 'vue_widgets/components'
import 'vue_widgets/mixins/turbolinks'
import 'vue_widgets/filters/pluralize'
import 'vue_widgets/filters/compact_integer'
import 'vue_widgets/filters/thousands_separator'

import 'styxies/admin/knowledge_base_questions'
import 'styxies/admin/users'
import 'styxies/admin/posts'
import 'styxies/admin/subscription_cancelations'
import 'styxies/admin/private_instance_requests'
import 'styxies/admin/geogrids_statistics'
import 'styxies/stats/gmb/geogrid_configs'

import 'styxies/gmb/questions/bulk_qna'
import 'styxies/gmb/bulk_post'
import 'styxies/gmb/keywords'
import 'styxies/gmb/local_posts'
import 'styxies/gmb/locations'
import 'styxies/gmb/media'
import 'styxies/gmb/multi_location_media'
import 'styxies/gmb/multi_location_posts'
import 'styxies/gmb/questions'
import 'styxies/gmb/reviews'
import 'styxies/gmb/service_lists'

import 'styxies/mobile/checkins'
import 'styxies/mobile/business'
import 'styxies/mobile/employees'
import 'styxies/mobile/widgets'
import 'styxies/mobile/service_titan/jobs'

import 'styxies/profile/geogrid_transactions'
import 'styxies/profile/keyword_transactions'

import 'styxies/dashboard'
import 'styxies/reviews_dashboard'
import 'styxies/global'
import 'styxies/google_accounts'
import 'styxies/facebook_accounts'
import 'styxies/billing'
import 'styxies/payment_data'
import 'styxies/geogrids'
import 'styxies/places_apis'
import 'styxies/report_configurations'
import 'styxies/white_label_configurations'
import 'styxies/users'
import 'styxies/reports'
import 'styxies/subscription_cancelations'
import 'styxies/organizations'
import 'styxies/organizations_locations'
import 'styxies/exif_templates'
import 'styxies/gallery'
import 'styxies/geogrid_widgets'
import 'styxies/geogrid_requests'
import 'styxies/api_tokens'
import 'styxies/api_service_titan'
import 'styxies/media_schedules'
import 'styxies/users_registrations'

import 'styxies/stats/reports'
import 'styxies/stats/audit_reports'
import 'styxies/stats/geogrid_comparison'
import 'styxies/stats/keywords'

import 'styxies/audit_report_configurations'
import 'styxies/audit_reports'

import 'styxies/downloads'

import 'styxies/jensen/telephony_accounts'

import 'init'

require.context('../images/emails', true)
require.context('../images/pdf', true)
require.context('../images/no_data', true)

require.context('../images/lv', true)
require.context('../images/lbm', true)
