<template>
  <div>
    <FilterComponent class="mb-4 pb-2"
      ref="reports-filter"
      :fields="fields"
      :disabled="isLoading"
      @onFilterChange="onFilterChange" />

    <template v-if="showTable">
      <div class="table-bulk-head reports-table">
        <TableHead class="reports-table" :columns="columns">
          <template #thead:created_at>
            <AppCheckbox class="gap-4"
              :part-selected="isPartSelected"
              :value="checkedAll"
              @input="toogleCheckedAll"
              @click.native.stop>
              Created At
            </AppCheckbox>
          </template>
        </TableHead>
        <TableActions
          :selected-count="selectedCount"
          :total-count="totalCount"
          :rows="reports"
          @toggleAll="toogleCheckedAll"
          @deleteReports="deleteReports" />
      </div>
      <VueSlimTable
        ref="table"
        :source="getReports"
        :columns="columns"
        :per-page="perPage"
        class="responsive-table borderless-table m-0 reports-table">
        <template #row="{ row }">
          <TableRow :row="row" @onRowCheckToggle="onRowCheckToggle" @deleteReports="deleteReports" />
        </template>
        <template #pagination>
          <Pagination class="pb-0"
            :page="currentPage"
            :per-page="perPage"
            :total-count="totalCount"
            @updatePage="updatePageNumber" />
        </template>
      </VueSlimTable>
    </template>
  </div>
</template>

<script>
import axiosTransform from 'common/axios'
import FilterComponent from 'vue_widgets/components/filter'
import Pagination from 'vue_widgets/components/pagination'
import AppCheckbox from 'vue_widgets/components/checkbox'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { runSwal } from 'common/delete_with_swal'
import TableHead from './table/table_head'
import TableActions from './table/table_actions'
import TableRow from './table/table_row'

export default {
  components: {
    FilterComponent,
    Pagination,
    TableHead,
    TableActions,
    TableRow,
    AppCheckbox
  },
  props: {
    apiBasePath: { type: String, required: true },
    reportLocations: { type: Array, default: () => []},
    reportConfigurations: { type: Array, default: () => []}
  },
  data() {
    return {
      reports: [],
      checkedAll: false,
      showTable: false,
      isLoading: false,

      currentPage: 1,
      totalCount: 0,

      fields: [{
        type: 'select',
        key: 'by_configuration',
        title: 'Configuration',
        classes: 'w-100',
        placeholder: 'All',
        options: this.reportConfigurations,
        trackBy: 'id',
        label: 'title',
        alternateLabel: 'title',
        searchable: true,
        showLabels: false,
        allowEmpty: true,
        maxHeight: 540,
        optionClasses: 'text-normal'
      }, {
        type: 'select',
        key: 'by_location',
        title: 'Location',
        classes: 'w-100',
        placeholder: 'All',
        options: this.reportLocations,
        trackBy: 'remoteId',
        label: 'name',
        searchable: true,
        showLabels: false,
        allowEmpty: true,
        multiple: false,
        maxHeight: 540
      }, {
        type: 'dateRange',
        key: 'by_range',
        title: 'Created At',
        classes: 'w-100',
        placeholder: 'All',
        maxDate: 'today',
        minDate: null
      }]
    }
  },
  created() {
    this.perPage = 20
    this.columns = [
      { key: 'created_at', title: 'Created At', orderable: true },
      { key: 'locations', title: 'Location', orderable: false },
      { key: 'configurationName', title: 'Configuration Name', orderable: false },
      { key: 'periods', title: 'Periods', orderable: false },
      { key: 'actions', title: 'Actions', orderable: false }
    ]
  },
  mounted() {
    const query = window.location.search.charAt(0) === '?' ? window.location.search.substring(1) : window.location.search
    const [key, value] = query.split('=')
    if (key && value) {
      const config = this.reportConfigurations.find((c) => c.id === value)
      this.$refs['reports-filter'].onFilterChange({ key, trackBy: 'id' }, config, 0, true)
    } else {
      this.showTable = true
    }
  },
  computed: {
    selectedCount() {
      return this.checkedAll ? this.totalCount : this.reports.filter((row) => row.checked).length
    },
    isPartSelected() {
      return this.selectedCount !== 0 && this.selectedCount !== this.totalCount
    }
  },
  methods: {
    getReports(prms) {
      this.isLoading = true
      return axios.get('/api/reports', {
        params: {
          ...prms,
          ...this.filter,
          page: this.currentPage
        },
        paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) },
        ...axiosTransform
      }).then((res) => {
        this.totalCount = parseInt(res.headers['total-count'], 10)
        this.reports = res.data.map((report) => ({ checked: this.checkedAll, ...report }))
        return this.reports
      }).finally(() => {
        setTimeout(() => {
          this.isLoading = false
        }, 100)
      })
    },
    deleteReports(reportIds) {
      runSwal(() => {
        axios.post('/api/reports/bulk_destroy', { reportIds }, axiosTransform)
          .then(() => {
            toastr.success(`Report${reportIds.length === 1 ? ' was' : 's were'} removed`)
            this.reload()
          })
          .catch((error) => {
            if (error?.response?.status === 403) return
            toastr.error(DEFAULT_ERROR_MESSAGE)
          })
      })
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.reload()
    },
    onFilterChange(newFilterValues) {
      this.filter = newFilterValues
      this.currentPage = 1
      this.toogleCheckedAll(false)
      if (!this.showTable) {
        // no need to reload since slim-table will
        // fetch in created hook anyway
        this.showTable = true
      } else {
        this.$nextTick(() => {
          this.reload()
        })
      }
    },
    reload() {
      this.$refs.table.reload()
    },
    toogleCheckedAll(value = null) {
      if (value === null) {
        if (this.isPartSelected) {
          this.checkedAll = false
        } else {
          this.checkedAll = !this.checkedAll
        }
      } else {
        this.checkedAll = value
      }

      this.reports.forEach((row) => {
        row.checked = this.checkedAll
      })
    },
    onRowCheckToggle(rowId) {
      this.reports = this.reports.map((row) => {
        if (row.id === rowId) row.checked = !row.checked
        return row
      })

      this.checkedAll = !this.reports.find((row) => !row.checked) && this.totalCount <= this.perPage
    }
  }
}
</script>
