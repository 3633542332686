<template>
  <tr>
    <td class="align-top" column-caption="Created">
      <a :href="linkToMLPShow">{{ row.createdAt }}</a>
    </td>
    <td class="align-top" column-caption="Locations">
      <div class="d-flex flex-column gap-2">
        <span v-for="{ id, name, storeCode } in locationList" :key="id">
          {{ name }}
          <small v-if="!storeCode" class="d-block text-muted">{{ storeCode }}</small>
        </span>
      </div>
      <a v-if="isMoreBthShown" :href="linkToMLPShow" class="btn btn-link gap-2">
        <i class="far fa-chevron-right" />
        <span class="font-size-12">{{ textMoreBtn }}</span>
      </a>
    </td>
    <td class="align-top" column-caption="Publishing Date">
      {{ row.scheduledInLocationTz }}
    </td>
    <td class="align-top" column-caption="Total Posts">
      {{ row.postsCount }}
    </td>
    <td class="align-top" column-caption="Author">
      {{ row.author }}
    </td>
    <td class="align-top text-right" column-caption="Actions">
      <button :class="['btn btn-link btn-danger', { disabled: isEditingDisabled }]" @click="deleteMulitLocation(row.id)">
        <i class="far fa-trash" />
      </button>
    </td>
  </tr>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

const DEFAULT_LOCATION_LIMIT = 3

export default {
  props: {
    row: { row: Object, required: true },
    baseUrl: { type: String, required: true },
    apiBaseUrl: { type: String, required: true },
    firstLocationId: { type: String, default: '' }
  },
  created() {
    this.isEditingDisabled = !Styxie?.USER?.editor
  },
  computed: {
    locationList() {
      const firstLocationIndex = this.row.locations.findIndex(({ id }) => id === this.firstLocationId)
      if (firstLocationIndex === -1) {
        return this.row.locations.slice(0, DEFAULT_LOCATION_LIMIT)
      }

      return [
        this.row.locations[firstLocationIndex],
        ...this.row.locations.filter(({ id }) => id !== this.firstLocationId).slice(0, DEFAULT_LOCATION_LIMIT - 1)
      ]
    },
    isMoreBthShown() {
      return DEFAULT_LOCATION_LIMIT < this.row.locations.length
    },
    textMoreBtn() {
      return `Show more (${this.row.locations.length - DEFAULT_LOCATION_LIMIT})`
    },
    linkToMLPShow() {
      return `${this.baseUrl}/${this.row.id}`
    }
  },
  methods: {
    deleteMulitLocation(id) {
      swal({
        text: 'Are you sure? Once deleted, you will not be able to recover this!',
        icon: 'warning',
        buttons: ['Cancel', 'Delete'],
        dangerMode: true
      }).then((result) => {
        if (result) {
          axios.delete(`${this.apiBaseUrl}/${id}`).then(() => {
            toastr.success('Multilocation post successfuly deleted.')
            this.$emit('reload')
          }).catch((error) => {
            if (error?.response?.status === 403) return
            toastr.error(DEFAULT_ERROR_MESSAGE)
          })
        }
      })
    }
  }
}
</script>
