<template>
  <div class="form-group">
    <div v-if="!object.remoteId" class="row align-items-center">
      <label class="col-12 col-sm-3 col-form-label text-sm-right">Recurring {{ text }}?</label>

      <div class="col-12 col-sm-8 col-lg-6">
        <div class="switch-button switch-button-success">
          <input type="checkbox" name="gmb_local_post[schedule_post]" id="gmb_local_post_recurent" v-model="recurrent">
          <span>
            <label for="gmb_local_post_recurent" />
          </span>
        </div>
      </div>
    </div>

    <transition name="slide-animation">
      <div v-show="recurrent">
        <div class="form-group row mt-2">
          <label class="col-12 col-sm-3 col-form-label text-sm-right" for="local_post_recurrent_days">Recurrent days</label>
          <div class="col-12 col-sm-8 col-lg-6">
            <input v-model.number="recurrentDays" :disabled="object.remoteId" class="form-control"
              min="1"
              step="1"
              type="number" id="gmb_local_post_recurrent_days">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-12 col-sm-3 col-form-label text-sm-right" for="local_post_recurrent_expire_count">Recurrent expire count</label>
          <div class="col-12 col-sm-8 col-lg-6">
            <input v-model.number="recurrentExpireCount" :disabled="object.remoteId" class="form-control"
              min="1"
              step="1"
              type="number" id="gmb_local_post_recurrent_expire_count">
          </div>
        </div>

        <div v-if="isShiftableTopic" class="form-group row align-items-center">
          <label class="col-12 col-sm-3 col-form-label text-sm-right">Shift event start/end date</label>

          <div class="col-12 col-sm-8 col-lg-6">
            <div class="switch-button switch-button-success">
              <input type="checkbox" name="gmb_local_post[shiftable_event_schedule]"
                id="shiftable_event_schedule" v-model="object.shiftableEventSchedule">
              <span>
                <label for="shiftable_event_schedule" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: ['text'],
  data() {
    return {
      checked: false
    }
  },
  computed: {
    ...mapState(['object', 'topicTypes']),
    isShiftableTopic() {
      return this.object.topicType === 'event' || this.object.topicType === 'offer'
    },
    recurrent: {
      get() { return !!this.object.recurrentDays || this.checked },
      set(val) {
        if (!val) {
          this.recurrentDays = ''
          this.recurrentExpireCount = ''
        }
        this.checked = val
        return val
      }
    },
    recurrentDays: {
      get() {
        return this.object.recurrentDays
      },
      set(count) {
        this.updateRecurrentDays(count)
      }
    },
    recurrentExpireCount: {
      get() {
        return this.object.recurrentExpireCount
      },
      set(count) {
        this.updateRecurrentExpireCount(count)
      }
    }
  },
  methods: mapMutations(['updateRecurrentDays', 'updateRecurrentExpireCount'])
}
</script>
