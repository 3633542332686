<template>
  <div>
    <div class="form-group row align-items-center mb-3">
      <div class="col-12 col-sm-3 text-sm-right">
        App Key
      </div>
      <div class="col-12 col-lg-4 col-md-6 col-sm-9 form-field">
        <input :disabled="isRequesting" type="text" v-model="appKey">
      </div>
    </div>
    <div class="form-group row align-items-center mb-3">
      <div class="col-12 col-sm-3 text-sm-right">
        Tenant
      </div>
      <div class="col-12 col-lg-4 col-md-6 col-sm-9 form-field">
        <input :disabled="isRequesting" type="text" v-model="tenant">
      </div>
    </div>
    <div class="form-group row align-items-center mb-3">
      <div class="col-12 col-sm-3 text-sm-right">
        Client ID
      </div>
      <div class="col-12 col-lg-4 col-md-6 col-sm-9 form-field">
        <input :disabled="isRequesting" type="password" v-model="clientId">
      </div>
    </div>
    <div class="form-group row align-items-center mb-3">
      <div class="col-12 col-sm-3 text-sm-right">
        Client Secret
      </div>
      <div class="col-12 col-lg-4 col-md-6 col-sm-9 form-field">
        <input :disabled="isRequesting" type="password" v-model="clientSecret">
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-3" />
      <div class="col-12 col-lg-4 col-md-6 col-sm-9">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-wrap justify-content-between gap-3">
            <button :disabled="isRequesting" class="btn btn-primary rounded" @click="submit">Update</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toastr from 'toastr'

export default {
  props: {
    credentials: { type: Object, required: true }
  },
  data() {
    return {
      isRequesting: false,
      appKey: this.credentials.appKey,
      tenant: this.credentials.tenant,
      clientId: this.credentials.clientId,
      clientSecret: this.credentials.clientSecret
    }
  },
  methods: {
    submit() {
      const url = '/api/service_titan'
      this.isRequesting = true
      const params = {
        app_key: this.appKey,
        tenant: this.tenant,
        client_id: this.clientId,
        client_secret: this.clientSecret
      }
      axios.put(url, { organization: { service_titan_credentials: params } })
        .then((_) => {
          this.isRequesting = false
          toastr.success('ServiceTitan credentials updated')
        })
        .catch((err) => {
          this.isRequesting = false
          toastr.error(err.response.data.errors.join('\r\n'))
        })
    }
  }
}
</script>
