<template>
  <div>
    <div class='form-field'><span class='caption'>Search terms:</span></div>
    <div class='col'>
      <div ref='searchTermBoxContainer' class='row search-term-box-container'>
        <div v-show='showHotelAlert' class='text-warning py-2 text-center w-100'>
          <i class='far fa-info-circle pr-1' />  If you use hotel and rental-related search terms,
          Google can give you search results that are not related to GMB, thus they won't be listed on the Geogrid map.
        </div>
        <div
          class='pt-2 text-center text-muted w-100'
          v-show='searchTerms.length === 0'>
          - - No search terms added yet - -
        </div>
        <div
          v-for='(st, index) in searchTerms'
          :key='index'
          :class="{'off': !st.active, 'danger': includesDangerWord(st.text)}"
          class='search-term-box'
          :ref='unificateSearchTermText(st.text)'>
          <div
            :class="{'fixed-width': addFixedWidth()}"
            class='search-term'>
            {{ st.text }}
          </div>
          <div class='icons'>
            <div class='toggles'>
              <a @click='toggleStatusSearchTerm(st, true)' href='javascript:void(0);'>
                <i v-show='(!st.active) && (st.geogridsCount > 0)' class='far fa-toggle-off' aria-hidden='true' />
              </a>
              <a href='javascript:void(0);' @click='toggleStatusSearchTerm(st, false)'>
                <i v-show='(st.active === true) && (st.geogridsCount > 0)' class='far fa-toggle-on' aria-hidden='true' />
              </a>
              <i v-show="(st.active === 'updating')" class='far fa-spinner fa-pulse fa-fw' />
            </div>
            <i @click='ensureSearchTermDeletion(index)' class="far fa-xmark" />
          </div>
        </div>
      </div>
    </div>
    <div class='search-terms-select2-container'>
      <select :id='selectId' />
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import toastr from 'toastr'
import snakeCaseKeys from 'snakecase-keys'
import axiosTransform from '../../../../common/axios'
import { DEFAULT_ERROR_MESSAGE } from '../../../../common/constants'

export default {
  name: 'SearchTerms',
  components: { },
  props: {
    select2prefix: String
  },
  data() {
    return {
      searchTerms: [],
      fixedWidth: false,
      keywords: [],
      dangerSearchTerm: false,
      searchTermsPath: null,
      selectId: `${this.select2prefix}-search-terms`
    }
  },
  computed: {
    showHotelAlert() {
      const match = this.searchTerms.find((st) => st.text.includes('hotel') || st.text.includes('rent') ||
       st.text.includes('booking') || st.text.includes('motel'))
      return Boolean(match)
    }
  },
  mounted() {
    $(`#${this.selectId}`).select2({
      data: this.keywords,
      width: '100%',
      placeholder: 'New search terms',
      tags: true,
      multiple: true,
      tokenSeparators: [','],
      createTag: this.createTagMethod,
      escapeMarkup: this.buildMarkup
    })
    $(`#${this.selectId}`).on('select2:selecting', this.createSearchTerm)
    $(`#${this.selectId}`).on('select2:close', () => { this.dangerSearchTerm = false })
  },
  methods: {
    includesDangerWord(word) {
      const arr = ['near', 'close', 'local', 'hotel', 'rent', 'booking', 'motel']
      return !!arr.includes(word)
    },
    showLoader() {
      $(this.$el).toggleWrapper({}, true)
    },
    hideLoader() {
      $(this.$el).toggleWrapper({}, false)
    },
    unificateSearchTermText(text) {
      const words = text.split(' ')
      for (let i = words.length - 1; i >= 0; i--) {
        if (words[i] === '') { words.splice(i, 1) }
      }
      return `search-term-${words.map((x) => x.toLowerCase()).join('_')}`
    },
    activatePlaceholder() {
      // https://github.com/select2/select2/issues/3553
      this.$nextTick(() => {
        $(`#${this.selectId}`).append(new Option('activate', 'activate', false, false)).trigger('change')
        $(`#${this.selectId} option[value='activate']`).remove()
      })
    },
    removeKeywords() {
      this.keywords.forEach((option) => {
        $(`#${this.selectId} option[value='${option.replaceAll("'", '&quot;')}']`).remove()
      })
    },
    renderKeywords(keywords) {
      this.$nextTick(() => {
        this.addFixedWidth()
        this.keywords = this.buildKeywords(keywords || [])
        this.keywords.forEach((option) => {
          $(`#${this.selectId}`).append(new Option(option, option, false, false)).trigger('change')
        })
      })
    },
    clearSearhTerms() {
      this.searchTerms = []
    },
    renderSearchTerms(config) {
      this.activatePlaceholder()
      this.searchTermsPath = config.searchTermsPath
      this.searchTerms = config.searchTerms || []
      this.removeKeywords()
      this.renderKeywords(config.keywords)
    },
    buildKeywords(keywords) {
      for (let i = keywords.length - 1; i >= 0; i--) {
        if (this.$refs[this.unificateSearchTermText(keywords[i])]) {
          keywords.splice(i, 1)
        }
      }
      return keywords
    },
    createSearchTerm(e) {
      e.preventDefault()
      const searchTermText = e.params.args.data.text
      if (!this.showSearchTerm(searchTermText)) {
        if (this.select2prefix === 'edit') {
          this.showLoader()
          axios.post(this.searchTermsPath, { searchTerm: { text: searchTermText } }, axiosTransform).then((response) => {
            if (!response.data.error) {
              this.searchTerms.splice(0, 0, response.data)
              const keywordIndex = this.keywords.indexOf(searchTermText)
              if (keywordIndex > -1) { this.keywords.splice(keywordIndex, 1) }
            }
          }).catch((error) => {
            if (error?.response?.status === 403) return
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }).finally(() => {
            this.hideLoader()
            this.$nextTick(() => {
              this.showSearchTerm(searchTermText)
              this.addFixedWidth()
            })
            $(`#${this.selectId} option[value='${searchTermText.replaceAll("'", '&quot;')}']`).select2().remove()
            $(`#${this.selectId}`).select2('close')
          })
        } else {
          this.searchTerms.splice(0, 0, {
            id: searchTermText.replace(/["']/g, '&quot;'), text: searchTermText, active: true, geogridsCount: 0
          })
          const keywordIndex = this.keywords.indexOf(searchTermText)
          if (keywordIndex > -1) { this.keywords.splice(keywordIndex, 1) }
          this.$nextTick(() => {
            this.showSearchTerm(searchTermText)
            this.addFixedWidth()
          })
          $(`#${this.selectId} option[value='${searchTermText.replaceAll("'", '&quot;')}']`).select2().remove()
          $(`#${this.selectId}`).select2('close')
        }
      }
    },
    showSearchTerm(searchTermText) {
      const element = this.$refs[this.unificateSearchTermText(searchTermText)]
      if ((element) && (element[0])) {
        element[0].scrollIntoView({ block: 'center' })
        element[0].classList.add('show')
        setTimeout(() => { element[0].classList.remove('show') }, 1000)
        return true
      }
      return false
    },
    ensureSearchTermDeletion(index) {
      if (this.searchTerms[index].geogridsCount !== 0) {
        swal({
          text: `This will also lead to deletion of ${this.searchTerms[index].geogridsCount} geogrids`,
          icon: 'warning',
          buttons: ['Cancel', 'Delete all'],
          dangerMode: true
        }).then((result) => {
          if (result) this.deleteSearchTerm(index)
        })
      } else {
        this.deleteSearchTerm(index)
      }
    },
    deleteSearchTerm(index) {
      const searchTerm = this.searchTerms[index]
      if (this.select2prefix === 'edit') {
        this.showLoader()
        axios.delete(
          this.searchTermsPath,
          {
            params: {
              searchTerm: {
                text: searchTerm.text
              }
            },
            paramsSerializer(json) {
              return qs.stringify(snakeCaseKeys(json, { deep: true }), { arrayFormat: 'brackets' })
            },
            ...axiosTransform
          }
        ).then((response) => {
          if (!response.data.error) {
            this.searchTerms.splice(index, 1)
            this.keywords.push(searchTerm.text)
            $(`#${this.selectId}`).append(new Option(searchTerm.text, searchTerm.text, false, false)).trigger('change')
            this.$nextTick(() => {
              this.addFixedWidth()
            })
          }
        }).catch((error) => {
          if (error?.response?.status === 403) return
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }).finally(() => {
          this.hideLoader()
        })
      } else {
        this.searchTerms.splice(index, 1)
        this.keywords.push(searchTerm.text)
        $(`#${this.selectId}`).append(new Option(searchTerm.text, searchTerm.text, false, false)).trigger('change')
        this.$nextTick(() => {
          this.addFixedWidth()
        })
      }
    },
    toggleStatusSearchTerm(searchTerm, newStatus) {
      searchTerm.active = 'updating'
      axios.post(`${this.searchTermsPath}/edit`, { searchTerm: { text: searchTerm.text, active: newStatus } }, axiosTransform).then((response) => {
        searchTerm.active = response.data.active
      }).catch((error) => {
        searchTerm.active = !newStatus
        if (error?.response?.status === 403) return
        toastr.error(DEFAULT_ERROR_MESSAGE)
      })
    },
    addFixedWidth() {
      this.fixedWidth = this.$refs.searchTermBoxContainer.clientHeight >= 198
      return this.fixedWidth
    },
    createTagMethod(params) {
      const term = $.trim(params.term)

      if (this.includesDangerWord(term)) { this.dangerSearchTerm = true } else { this.dangerSearchTerm = false }

      if (term === '') return null

      return { id: term, text: term }
    },
    buildMarkup(markup) {
      if (markup === 'No results found') return null
      if (this.keywords.length === 0) return `Press Enter to add '${markup}'`
      return markup
    },
    getSearchTerms() {
      return this.searchTerms
    }
  }
}
</script>

<style scoped>
  .search-term-box-container{
    max-height: 200px;
    min-height: 44px;
    overflow: auto;
    border: 2px solid #ebebeb;
    border-bottom: none;
    height: auto;
    scroll-behavior: smooth;
    transition: 3s ease;
  }
  .search-term-box{
    width: fit-content;
    background-color: #88b1ff;
    color: black;
    display: flex;
    padding: 2px 7px;
    border-radius: 4px;
    margin: 6px;
    transition: .3s ease;
    height: fit-content;
    box-shadow: 0px 1px 2px transparent;
  }
  .search-term-box.show{
    box-shadow: 0px 1px 2px black;
    transform: translate(0px, -2px);
  }
  .search-term-box.off{
    opacity: 0.7;
    background-color: #f4f4f4;
  }
  .search-term-box.danger{
    background-color: #ffa200;
  }
  .search-term-box .search-term{
    width: fit-content;
    max-width: 113px;
  }
  .search-term.fixed-width {
    width: 113px !important;
  }
  .search-term-box .icons {
    width: fit-content;
    padding-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .icons .far.fa-xmark {
    font-size: 12px;
    cursor: pointer;
  }
  .toggles {
    display: inline-block;
    width: 19px;
  }
  .search-term-box .icons a {
    color: black;
  }
</style>
