<template>
  <div class="d-flex gap-4 position-relative flex-wrap flex-lg-nowrap">
    <div class="h-100 position-absolute w-100 filters-overlay" :class="{ active: disabled }" />
    <div v-for="field in fields"
      :key="field.key"
      class="form-field"
      :class="field.classes"
      :style="{ maxWidth: fields.length > 3 ? `${100 / (fields.length + 1)}%` : 'auto'}">
      <span :class="field.titleClasses || ''">
        {{ field.title || '&nbsp;' }}
        <template v-if="field.hint">
          <i class="fa-info-circle far small ml-1" :id="`${_uid}-${field.key}`" />
          <b-popover :target="`${_uid}-${field.key}`" triggers="hover" placement="top">
            <div class='text-dark'>
              {{ field.hint }}
            </div>
          </b-popover>
        </template>
      </span>
      <InputSearch v-if="field.type === 'search'"
        :value="filterValues[field.key]"
        :placeholder="field.placeholder"
        @input="onFilterChange(field, $event, 1500)" />
      <CustomSelect v-else-if="field.type === 'select'"
        :option-data="field"
        :value="filterValues[field.key]"
        @onInput="onFilterChange(field, $event, 0)" />
      <DatePicker v-else-if="field.type === 'selectMonth'"
        class="w-100 minw-0"
        date-format="Y-m-d"
        :just-months="true"
        :value="filterValues[field.key] || []"
        @input="onFilterChange(field, $event)" />
      <DatePicker v-else-if="field.type === 'dateRange'"
        class="w-100 minw-0"
        mode="range"
        :max-date="field.maxDate"
        :min-date="field.minDate"
        :date-format="field.dateFormat || 'Y-m-d'"
        :value="filterValues[field.key] || []"
        :placeholder="field.placeholder || ''"
        @input="onFilterChange(field, $event)" />
      <input v-else
        type="text"
        :value="filterValues[field.key]"
        :placeholder="field.placeholder"
        @input="onFilterChange(field, $event.target.value, 1500)">
    </div>
    <div class="form-field" :class="{ 'opacity-50': !anyFiltersSelected }">
      <span class="d-none d-lg-block">&nbsp;</span>
      <button class="btn btn-pretender px-0 border-0 gap-2 nowrap"
        :class="{'text-muted': !anyFiltersSelected}"
        :disabled="!anyFiltersSelected"
        @click="clear">
        <i class="far fa-eraser" aria-hidden="true" />
        Clear filters
      </button>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue_widgets/components/datepicker'
import InputSearch from 'vue_widgets/components/input_search_ui'
import CustomSelect from 'vue_widgets/components/filter/select'
import { extractSessionStorage, insertSessionStorage } from 'common/helpers'

export default {
  components: { InputSearch, CustomSelect, DatePicker },
  props: {
    fields: { type: Array, required: true },
    sessionKey: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  },
  data() {
    return {
      filterValues: {},
      compiledFilters: {}
    }
  },
  created() {
    this.restoreFilter()
  },
  methods: {
    onFilterChange(fieldOption, $event, delay = 0, clearFilter = false) {
      if (clearFilter) {
        this.filterValues = {}
        this.compiledFilters = {}
      }

      clearTimeout(this.timeout)
      this.$set(this.filterValues, fieldOption.key, $event)

      this.timeout = setTimeout(() => {
        this.compiledFilters[fieldOption.key] = this.compileFilterValue(fieldOption)
        this.$emit('onFilterChange', this.compiledFilters)
      }, delay)

      insertSessionStorage(this.sessionKey, this.filterValues)
    },
    clear() {
      this.filterValues = {}
      this.compiledFilters = {}
      this.$emit('onFilterChange', {})

      insertSessionStorage(this.sessionKey, this.filterValues)
    },
    restoreFilter() {
      if (!this.sessionKey) return

      this.filterValues = extractSessionStorage(this.sessionKey) || {}
      if (Object.keys(this.filterValues).length) {
        this.fields.forEach((field) => {
          if (!this.filterValues[field.key]) return
          this.compiledFilters[field.key] = this.compileFilterValue(field)
        })
        this.$emit('onFilterChange', this.compiledFilters)
      }
    },
    compileFilterValue(field) {
      if (typeof this.filterValues[field.key] === 'object' &&
        !Array.isArray(this.filterValues[field.key]) &&
        this.filterValues[field.key]) {
        return this.filterValues[field.key][field.trackBy]
      }
      if (Array.isArray(this.filterValues[field.key])) {
        return this.filterValues[field.key].map((value) => (field.trackBy ? value[field.trackBy] : value))
      }

      return this.filterValues[field.key]
    }
  },
  computed: {
    anyFiltersSelected() {
      const selected = Object.keys(this.filterValues).some((filterItem) => {
        const filter = this.filterValues[filterItem]
        if (Array.isArray(filter)) {
          return filter.length
        }
        return filter
      })

      return selected
    }
  }
}
</script>
