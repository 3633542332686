<template>
  <div>
    <h2 class="h3 m-0 mb-2 font-weight-semi-bold text-dark">Hubspot integration</h2>
    <form class="narrow-form mb-4" @submit.prevent="updateBusiness">
      <div :class="['d-flex gap-4 form-field pt-1', { error } ]">
        <input v-once :value="hubspotId" placeholder="Hubspot ID"
          type="text"
          name="mobile_business[hubspot_id]">
        <button type="submit" class="btn btn-outline rounded">Update</button>
      </div>
      <small v-if="error" class="text-danger">{{ error }}</small>
    </form>
  </div>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import axiosTransform from 'common/axios'

export default {
  props: {
    businessId: { type: String, required: true },
    hubspotId: { type: String, default: '' }
  },
  data() {
    return {
      error: null
    }
  },
  methods: {
    updateBusiness(e) {
      this.error = null
      const data = new FormData(e.target)

      return axios.put(`/gb/ajax/businesses/${this.businessId}`, data, { transformResponse: axiosTransform.transformResponse })
        .then(() => {
          toastr.success('Successfully saved')
        })
        .catch((err) => {
          if (err.response.data.type === 'validation' && err.response.data.errors.hubspotId.length) {
            this.error = err.response.data.errors.hubspotId.join(', ')
            return
          }

          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    }
  }
}
</script>
