<template>
  <div>
    <div class="geogrid-comparison__common-info p-4 mb-2 no-print">
      <div>
        <span class="d-block text-muted">Search Term:</span>
        <span>{{ row.searchTerm }}</span>
      </div>
    </div>

    <table class="geogrids-comparing">
      <tbody>
        <tr>
          <td
            v-for="(gg, index) in geogrids"
            :key="`${row.searchTerm}-${index}`"
            :class="{ 'no-data': noDataForSelectedPeriod(gg, index) }">
            <p v-if="noDataForSelectedPeriod(gg, index)" class="font-weight-bold mt-3 text-dark">
              No data available for the selected range
            </p>
            <div v-else class="geogrid-comparison__common-info geogrid-comparison__common-info--bg-white p-3">
              <span>{{ gg.createdAtFormatted }}</span>
              <div class="d-flex align-items-center gap-4 font-size-14">
                <div class="d-flex align-items-center gap-1">
                  <span class="text-muted font-size-14">AGR:</span>
                  <span :class="['geogrid-data', { [geogridDiff.agr.wrapClass]: index === 1 }]">
                    {{ toFixed(gg.agr) }}
                    <template v-if="index === 1 && !isGeogridValuesNaN('agr')">
                      <i :class="geogridDiff.agr.iconClass" />
                      <span>{{ geogridDiff.agr.diff.toFixed(2) }}</span>
                    </template>
                  </span>
                </div>
                <div class="d-flex align-items-center gap-1">
                  <span class="text-muted font-size-14">ATGR:</span>
                  <span :class="['geogrid-data', { [geogridDiff.atgr.wrapClass]: index === 1 }]">
                    {{ toFixed(gg.atgr) }}
                    <template v-if="index === 1 && !isGeogridValuesNaN('atgr')">
                      <i :class="geogridDiff.atgr.iconClass" />
                      <span>{{ geogridDiff.atgr.diff.toFixed(2) }}</span>
                    </template>
                  </span>
                </div>
                <div class="d-flex align-items-center gap-1">
                  <span class="text-muted font-size-14">SoLV:</span>
                  <span :class="['geogrid-data', { [geogridDiff.solv.wrapClass]: index === 1 }]">
                    {{ gg.solvFormatted }}
                    <template v-if="index === 1 && !isGeogridValuesNaN('solv')">
                      <i :class="geogridDiff.solv.iconClass" />
                      <span>{{ geogridDiff.solv.diff }}%</span>
                    </template>
                  </span>
                </div>
              </div>
              <div class="geogrid-map__container">
                <img
                  v-if="gg.imagePath || gg.imageUrl"
                  v-bind="ggImageAttrs(gg)"
                  alt="Static geogrid preview">
                <p v-else class="font-weight-bold mt-3 text-dark">
                  No image available
                </p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import geogridComparisonMixin from 'vue_widgets/mixins/geogrid_comparison_mixin'

export default {
  mixins: [geogridComparisonMixin],
  name: 'Geogrid',
  props: {
    assetsHost: {
      type: String,
      required: true
    },
    row: {
      type: Object,
      required: true
    }
  },
  computed: {
    geogrids() {
      const { first, last } = this.row
      return [first, last]
    }
  },
  methods: {
    noDataForSelectedPeriod(gg, index) {
      if (index !== 1) {
        return false
      }

      return this.geogrids[0].imagePath === gg.imagePath && this.geogrids[0].imageUrl === gg.imageUrl
    },
    ggImageAttrs(gg) {
      let cssClass = 'geogrid-map--static geogrid-map--static-visible'

      if (!gg.imageUrl) {
        cssClass += ' geogrid-map--static-translate-up'
      }

      const src = gg.imageUrl ?? `https://${this.assetsHost}/${gg.imagePath}`
      return {
        class: cssClass,
        src
      }
    }
  }
}
</script>
