<template>
  <div
    v-show="show"
    class="droparea text-center"
    @dragover.prevent="dragover"
    @dragleave="dragleave"
    @drop.prevent="drop">
    <div class="droparea-inner">
      <h2 class="mt-5">Upload images to Media Gallery</h2>
      <h4>.jpg .png</h4>
    </div>

    <input
      type="file"
      multiple
      @change="onChange"
      ref="file"
      accept=".jpg,.jpeg,.png">
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { NOT_AUTHORIZED_MESSAGE } from 'common/constants'

export default {
  data() {
    return {
      show: false,
      isEdititingDisabled: !Styxie?.USER?.editor
    }
  },
  mounted() {
    document.addEventListener('dragenter', (e) => {
      e.preventDefault()
      e.stopPropagation()

      this.show = true
    })
  },
  computed: {
    ...mapGetters(['getSelectedTagsCount'])
  },
  methods: {
    onChange() {
      if (this.isEdititingDisabled) {
        toastr.info(NOT_AUTHORIZED_MESSAGE)
        return
      }
      const arr = Array.from(this.$refs.file.files)
      this.addFiles(arr)
    },
    dragover() {
      this.show = true
    },
    dragleave() {
      this.show = false
    },
    drop(event) {
      this.show = false
      if (this.isEdititingDisabled) {
        toastr.info(NOT_AUTHORIZED_MESSAGE)
        return
      }

      const arr = Array.from(event.dataTransfer.files)

      if (this.getSelectedTagsCount) {
        this.unselectAllTags().then(() => {
          this.addFiles(arr)
        })
      } else {
        this.addFiles(arr)
      }
    },
    ...mapActions(['addFiles', 'unselectAllTags'])
  }
}
</script>
