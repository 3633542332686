<template>
  <div class="live-geogrid-details">
    <div class="live-geogrid-details__wrapper">
      <div class="live-geogrid-details__card">
        <button class="live-geogrid-details__back btn btn-sm btn-pretender"
          @click="goTo('index')">
          <i class="pr-2 fa-regular fa-arrow-left-long" />
          <span>Back to list</span>
        </button>
        <div class="live-geogrid-details__top">
          <component class="live-geogrid-details__name" :is="attrs.locationPath ? 'a' : 'span'" target="_blank" :href="attrs.locationPath">
            <span>{{ attrs.businessName }}</span>
            <i v-if="attrs.locationPath" class="fa-regular fa-arrow-up-right-from-square" />
          </component>
          <button v-if="attrs.businessLocationLat"
            class="live-geogrid-details__show-business"
            @click="toggleShowBusinessLocation">
            <i class="far fa-map-marker" :class="{'fas': businessLocationVisible}" />
          </button>
        </div>
        <p v-if="attrs.storeCode" class="live-geogrid-details__store-code">{{ attrs.storeCode }}</p>
        <p v-if="attrs.businessAddress" class="live-geogrid-details__address">{{ attrs.businessAddress }}</p>
        <CategoriesList v-if="attrs.businessCategories?.length" :categories="attrs.businessCategories" />
        <div class="live-geogrid-details__search-term">
          <p>Search term:</p>
          <span>{{ geogrid.searchTerm }}</span>
        </div>

        <button class="live-geogrid-details__copy btn btn-semi-primary" @click="copyPlaceId">
          <span>Copy Place ID</span>
          <i class="far fa-copy" />
        </button>
        <hr class="live-geogrid-details__separator">

        <Tags ref="tags"
          class="live-geogrid-details__tags"
          :show-edit-buttons="true"
          :add-tag-path="geogrid.addTagPath"
          :remove-tag-path="geogrid.removeTagPath"
          :initial-tags="geogrid.tags"
          @updateRowWithNewTags="updateRowWithNewTags" />

        <div class="live-geogrid-details__note">
          <span v-if="openNote || note?.length" class="text-dark bold">Note:</span>
          <div class="form-field">
            <quick-edit
              empty-text="Note:"
              type="textarea"
              :classes="vueQuickEditClasses"
              :show-buttons="false"
              @show="showTextareaNote"
              @close="updateNote"
              v-model="note" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuickEdit from 'vue-quick-edit'
import toastr from 'toastr'
import { mapMutations, mapActions, mapState } from 'vuex'
import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'
import Tags from '../components/tags'
import CategoriesList from '../components/categories_list'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

export default {
  name: 'GeogridDetails',
  components: { Tags, CategoriesList, QuickEdit },
  props: {
    apiGeogridsPath: String
  },
  data() {
    return {
      openNote: false,
      note: '',
      businessLocationVisible: false
    }
  },
  computed: {
    ...mapState(['geogrid']),
    attrs() { return this.geogrid.attrs }
  },
  created() {
    this.vueQuickEditClasses = { input: 'textareaClass scroll', link: 'text-break' }
    this.note = this.geogrid?.note
  },
  methods: {
    ...mapMutations(['toggleBusinessMarker']),
    ...mapActions(['goTo']),
    updateRowWithNewTags(newTags) {
      this.geogrid.tags = newTags
      this.$emit('reloadHistory')
    },
    updateNote(text) {
      this.note = text.trim()
      this.openNote = false
      axios.put(`${this.apiGeogridsPath}/${this.geogrid.obfuscatedId}`, { note: this.note }).catch((error) => {
        if (error?.response?.status === 403) return
        toastr.error(DEFAULT_ERROR_MESSAGE)
      })
    },
    resizeNote() {
      this.textarea.style.height = '0px'
      this.$nextTick(() => {
        this.textarea.style.height = `${this.textarea.scrollHeight}px`
      })
    },
    showTextareaNote() {
      this.openNote = true
      this.$nextTick(() => {
        [this.textarea] = $('.textareaClass')
        this.resizeNote()
        this.textarea.addEventListener('input', this.resizeNote)
      })
    },
    copyPlaceId(e) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: this.attrs.businessPlaceId })
    },
    toggleShowBusinessLocation() {
      if (this.attrs.businessLocationLat) {
        this.toggleBusinessMarker({ attrs: this.attrs })
        this.businessLocationVisible = !this.businessLocationVisible
      }
    }
  }
}
</script>
