import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import toastr from 'toastr'

import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

Vue.use(Vuex)

function collectErrors(data) {
  try {
    if (data?.errors?.code === 500 || data?.errors?.code === 404) {
      return data.errors.message
    }
    let errors

    if (data?.errors?.details) {
      errors = Object.keys(data.errors.details).reduce((memo, key) => data.errors.details[key].reduce((mem, el) => {
        mem.push(el.message)
        return mem
      }, memo), [])
    } else if (data?.errors) {
      errors = Object.keys(data.errors).reduce((memo, key) => data.errors[key].reduce((mem, el) => {
        mem.push(el)
        return mem
      }, memo), [])
    } else if (data?.error) {
      return data.error
    }
    return errors.join('\n')
  } catch (err) { // eslint-disable-line no-unused-vars
    return DEFAULT_ERROR_MESSAGE
  }
}

export default function createStore(prms) {
  return new Vuex.Store({
    state: {
      ...prms,
      currentQuestion: null,
      page: 2,
      isShowMore: prms.questions.length === 10
    },
    actions: {
      switchQuestion({ commit }, payload) {
        commit('switchQuestion', payload)
      },
      fetchQuestions({ commit }) {
        axios.get(`/api${window.location.pathname}`, { params: { page: this.state.page } })
          .then((res) => {
            commit('addQuestions', res.data)
          })
      },
      deleteAnswer({ commit }, payload) {
        axios.delete(`/api${window.location.pathname}/${payload.question.remote_id}/answers/${payload.answer.remote_id}`)
          .then(() => {
            commit('deleteAnswer', payload)
          })
          .finally(() => {
            payload.cleanWrapperCb()
          })
      },
      upsertAnswer({ commit }, payload) {
        axios.post(`/api${window.location.pathname}/${payload.question.remote_id}/answers`, { gmb_answer: { text: payload.text } })
          .then((res) => {
            payload.cleanWrapperCb()
            if (payload.answer) return commit('changeAnswer', payload)
            return commit('addAnswer', res.data)
          })
          .catch((res) => {
            payload.cleanWrapperCb()
            if (res?.response?.status === 403) return
            toastr.error(collectErrors(res.response.data) || DEFAULT_ERROR_MESSAGE)
          })
      },
      postQuestion({ commit }, payload) {
        axios.post(`/api${window.location.pathname}/`, { gmb_question: { text: payload.text } })
          .then(
            (res) => {
              payload.cleanWrapperCb()
              commit('addQuestions', res.data)
            },
            (res) => {
              payload.cleanWrapperCb(false)
              if (res?.response?.status === 403) return
              toastr.error(collectErrors(res.response.data))
            }
          )
      },
      deleteQuestion({ commit }, payload) {
        axios.delete(`/api${window.location.pathname}/${payload.question.remote_id}`)
          .then(() => {
            commit('deleteQuestion', payload)
          })
          .finally(() => {
            payload.cleanWrapperCb()
          })
      }
    },
    mutations: {
      switchQuestion(state, question) {
        state.currentQuestion = question
      },
      addQuestions(state, payload) {
        state.questions = state.questions.concat(payload)
        state.page++
        if (payload.length < 10) { state.isShowMore = false }
      },
      deleteAnswer(state, payload) {
        payload.question.answers = payload.question.answers.filter((el) => el.remote_id !== payload.answer.remote_id)
      },
      changeAnswer(state, payload) {
        payload.answer.text = payload.text
      },
      addAnswer(state, payload) {
        state.currentQuestion.answers.push(payload)
      },
      deleteQuestion(state, payload) {
        if (state.currentQuestion && state.currentQuestion.remote_id === payload.question.remote_id) state.currentQuestion = null
        state.questions = state.questions.filter((el) => el.remote_id !== payload.question.remote_id)
      }
    }
  })
}
