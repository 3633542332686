<template>
  <div>
    <div>
      <b-row>
        <b-col md=8 sm=12 class='mb-2'>
          <div class='form-field'>
            <span class='caption'>Business:</span>
            <b>{{ config.trackedBusiness }}</b> <i><small>({{ config.attrs.businessPlaceId }})</small></i>
            <a
              href='javascript:void(0);'
              v-show='config.attrs.businessLocationLat'
              class='ml-2'
              @click='toggleShowBusinessLocation'>
              <i class='far fa-map-marker' />
            </a>
            <tags
              ref='tags'
              :show-edit-buttons='true'
              @updateRowWithNewTags='updateRowWithNewTags'
              :add-tag-path='config.addTagPath'
              :remove-tag-path='config.removeTagPath' />
          </div>
          <searchTerms
            class='mt-2'
            ref='searchTerms'
            :select2prefix="'edit'" />
        </b-col>
        <b-col v-show="config.attrs.placesDataSource === 'places_api'" cols=12>
          <span>Data engine:</span>
          <dataEngine
            :new-key-path='newKeyPath'
            :keys-path='keysPath'
            :places-data-source-options='placesDataSourceDefaultOptions'
            :places-api-keys-default='placesApiKeys'
            select2prefix='edit'
            ref='dataEngine' />
        </b-col>
        <b-col lg=4>
          <configSchedule
            :periodicity-options='periodicityOptions'
            :week-day-number-options='dayNumberOptions'
            :week-number-options='weekNumberOptions'
            :time-zones="timeZones"
            :time-zone="userTimeZone"
            select2prefix='edit'
            ref='configSchedule' />
          <languageResults class='mt-4' ref='languageResults' />
          <b-button
            class="w-100 mb-3"
            type="submit"
            variant="primary"
            :disabled="isEditingDisabled"
            @click="saveConfigChanges()">
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import toastr from 'toastr'
import { mapState, mapMutations } from 'vuex'
import searchTerms from './components/search_terms'
import configSchedule from './components/config_schedule'
import dataEngine from '../attrs_components/data_engine'
import tags from '../components/tags'
import languageResults from '../attrs_components/language'
import axiosTransform from '../../../common/axios'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

export default {
  name: 'EditConfig',
  components: {
    searchTerms, configSchedule, dataEngine, tags, languageResults
  },
  props: {
    periodicityOptions: Object,
    dayNumberOptions: Array,
    weekNumberOptions: Array,
    newKeyPath: String,
    keysPath: String,
    placesDataSourceDefaultOptions: Array,
    placesApiKeys: Array,
    apiConfigsPath: String,
    userTimeZone: String,
    timeZones: Array
  },
  data() {
    return {
      resultsIn: ['English', 'Local language'],
      language: 'English',
      isEditingDisabled: !Styxie.USER.editor
    }
  },
  computed: {
    ...mapState(['pageState', 'pageTab']),
    config: {
      get() { return this.$store.state.config },
      set(val) { this.$store.commit('config', val) }
    }
  },
  watch: {
    pageTab(newVal) {
      if (newVal === 'edit') this.renderDummyGrid({ ...this.config.attrs, saveDisabledPoints: true })
    },
    config(val) {
      if (!val.obfuscatedId) return
      this.$refs.tags.renderTags(val.tags || [])
      this.$refs.searchTerms.renderSearchTerms(val)
      this.$refs.configSchedule.renderForm(val.schedule, val.nextGeneration)
      this.$refs.dataEngine.renderForm(val.attrs)
      this.$refs.languageResults.language = val.attrs.localLanguageEnabled ? 'Local' : 'Global'

      // We put timezone to the store to behave the same as in the new config.
      this.$store.state.chosenTimeZone = val.schedule.timeZone
    }
  },
  methods: {
    ...mapMutations(['renderDummyGrid', 'toggleBusinessMarker', 'showLoader', 'hideLoader']),
    toggleShowBusinessLocation() {
      if (this.config.attrs.businessLocationLat) this.toggleBusinessMarker({ attrs: this.config.attrs })
    },
    updateRowWithNewTags(newTags) {
      this.config.tags = newTags
      this.$emit('reloadHistory', this.config)
    },
    saveConfigChanges() {
      const data = {
        config: {
          schedule: this.$refs.configSchedule.getSchedule,
          attrs: {
            ...this.$refs.dataEngine.getDataEngine(),
            gridCenterLat: this.$store.state.mapCenterLat,
            gridCenterLng: this.$store.state.mapCenterLng,
            gridPointDistance: this.$store.state.distance,
            gridDistanceMeasure: this.$store.state.distanceMeasure,
            gridSize: this.$store.state.gridSize,
            localLanguageEnabled: this.$refs.languageResults.languageFlag,
            disabledPoints: Array.from(this.$store.state.disabledPoints)
          }
        }
      }
      if (data.config.attrs.dataEngineFailed) {
        toastr.error('Please provide <b> proper data engine </b> to save config changes.')
      } else {
        this.showLoader()
        axios.put(`${this.apiConfigsPath}/${this.config.obfuscatedId}`, data, axiosTransform).then((response) => {
          this.config = response.data
          toastr.success(`Config for <b>${this.config.trackedBusiness}</b> was successfully updated.`)
          this.$emit('reloadHistory', response.data)
        }).catch((error) => {
          if (error?.response?.status === 403) return
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }).finally(() => {
          this.hideLoader()
        })
      }
    }
  }
}
</script>
