<template>
  <div :class="['keyword-full-chart', { public: isPublic }]">
    <div class="widget">
      <div class="keyword-full-chart-head">
        <strong class="caption">By Position</strong>
        <DatePicker
          class="inline"
          mode="range"
          max-date="today"
          :emit-when-create="true"
          :value="dateRange"
          @input="getKeywordData" />
      </div>
      <div id="keyword-full-chart" class="chart" />
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import qs from 'qs'
import moment from 'moment'
import { mapState } from 'vuex'
import DatePicker from '../../components/datepicker'
import { tooltipOptions } from '../locations/insights/helpers'
import { tooltipHeader, tooltipPoint } from './helpers'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

const chartColors = [
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffc107',
  '#f44336',
  '#cfd5dd'
]

export default {
  computed: mapState(['apiKeywordsPath']),
  components: { DatePicker },
  props: {
    isPublic: { type: Boolean, default: false }
  },
  data() {
    return {
      chart: null
    }
  },
  created() {
    this.dateRange = [
      moment().subtract(1, 'months').format('MM/DD/YYYY'),
      moment().format('MM/DD/YYYY')
    ]
  },
  methods: {
    drawChart(data) {
      if (this.chart) this.chart.destroy()

      const legendOptionsDefault = {
        navigation: { enabled: false },
        labelFormatter() {
          const lastValue = this.yData[this.yData.length - 1] || 0
          return `${this.name} (${lastValue})`
        }
      }

      const legendOptions = {
        layout: 'vertical',
        align: 'right',
        margin: 10,
        padding: 10,
        y: -20,
        itemMarginTop: 10,
        itemMarginBottom: 10,
        itemStyle: {
          fontSize: '16px',
          lineHeight: 1.5,
          fontWeight: 600
        },
        symbolHeight: 12,
        symbolPadding: 8,
        symbolRadius: 2,
        symbolWidth: 12,
        verticalAlign: 'top'
      }

      const legendOptionsPublic = {
        itemStyle: {
          fontSize: '12px',
          lineHeight: 1.25,
          fontWeight: 400
        },
        symbolHeight: 8,
        symbolWidth: 8,
        symbolPadding: 8,
        symbolRadius: 4
      }

      this.chart = Highcharts.chart('keyword-full-chart', {
        chart: {
          type: 'area',
          height: 250,
          style: {
            fontFamily: '"Nunito", sans-serif',
            fontSize: '16px',
            color: '#3d454d'
          }
        },
        credits: { enabled: false },
        xAxis: {
          tickPixelInterval: 200,
          type: 'datetime',
          title: {
            enabled: false
          },
          labels: {
            format: '{value: %b %e, %Y}',
            style: {
              fontSize: '14px',
              color: '#77838f'
            }
          }
        },
        yAxis: {
          title: {
            enabled: !this.isPublic,
            text: 'Ranks total',
            x: -10
          },
          labels: {
            style: {
              fontSize: '14px',
              color: '#77838f'
            }
          }
        },
        legend: {
          ...legendOptionsDefault,
          ...(this.isPublic ? legendOptionsPublic : legendOptions)
        },
        title: false,
        subtitle: { enabled: false },
        plotOptions: {
          area: {
            stacking: 'normal',
            fillOpacity: 1,
            marker: {
              enabled: false,
              symbol: 'circle'
            }
          }
        },
        tooltip: {
          ...tooltipOptions,
          shared: true,
          formatter() {
            const memo = []
            memo.push(tooltipHeader(this.x))
            this.points.forEach((point) => {
              memo.push(tooltipPoint(point))
            })
            return memo.join('')
          }
        },
        series: data
      })
    },
    async getKeywordData(range) {
      const params = {
        by_period: {
          start: range[0],
          end: range[1]
        }
      }
      const url = `${this.apiKeywordsPath}/full_chart_data?${qs.stringify(params)}`
      try {
        const { data } = await axios.get(url)
        data.forEach((serie, index) => {
          serie.color = chartColors[index]
        })
        this.drawChart(data)
      } catch (err) { // eslint-disable-line no-unused-vars
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }
    }
  }
}
</script>
