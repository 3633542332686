<template>
  <div class="dropdown-action dropdown">
    <div
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      :class="[['d-flex align-items-baseline gap-1', { 'disabled-row pointer-events-none': isEditingDisabled }]]">
      <slot />
    </div>
    <div class="dropdown-box dropdown-menu" ref="dropdownBox" @click.stop>
      <TagForm
        :tags="tagOptions"
        :show-button="true"
        :text="text"
        @saveTags="saveTags"
        @closeDropdown="closeDropdown" />
    </div>
  </div>
</template>

<script>
import TagForm from '../tags_select/tag_form'

export default {
  props: {
    tagOptions: {
      type: Array,
      default() { return [] }
    },
    text: {
      type: String,
      default: ''
    }
  },
  components: { TagForm },
  created() {
    this.isEditingDisabled = !Styxie?.USER?.editor
  },
  methods: {
    saveTags(tags) {
      this.closeDropdown()
      this.$emit('saveTags', tags)
    },
    closeDropdown() {
      this.$refs.dropdownBox.classList.remove('show')
    }
  }
}
</script>
