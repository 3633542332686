<template>
  <tr :class="{ 'mv-employee-blocked': row.blockedAt }">
    <td class="text-break w-20">
      <a v-if="!organizationLinda && hasAdminPrivileges" :href="employeeEditUrl">{{ row.name }} {{ row.lastName }}</a>
      <span v-else>{{ row.name }} {{ row.lastName }}</span>
    </td>
    <td class="text-break w-20">
      {{ row.email }}
    </td>
    <td class="text-primary w-8">
      <component
        :is="row.blockedAt ? 'span' : 'a'"
        :href="buildUrl({ status: 'pending' })"
        target="_blank">
        {{ row.pendingCount }}
      </component>
    </td>
    <td class="text-primary w-8">
      <component
        :is="row.blockedAt ? 'span' : 'a'"
        :href="buildUrl({ status: 'approved' })"
        target="_blank">
        {{ row.approvedCount }}
      </component>
    </td>
    <td class="text-primary w-8">
      <component
        :is="row.blockedAt ? 'span' : 'a'"
        :href="buildUrl({ status: 'rejected' })"
        target="_blank">
        {{ row.rejectedCount }}
      </component>
    </td>
    <td class="text-primary w-8">
      <component
        :is="row.blockedAt ? 'span' : 'a'"
        :href="buildUrl()"
        target="_blank">
        {{ row.totalCount }}
      </component>
    </td>
    <td class="text-break">
      <template v-if="!row.blockedAt">
        <span v-if="row.allBusinessesAccess" class="font-weight-semi-bold">All</span>
        <div v-else class="d-flex align-items-center gap-1">
          {{ row.businesses.length }}
        </div>
      </template>
      <span v-else>
        Blocked
      </span>
    </td>
    <td class="text-break">
      {{ row.description }}
    </td>
    <td class="text-center w-11">
      <label v-if="!row.blockedAt" :class="['switcher d-inline-flex', { disabled: !hasAdminPrivileges }]">
        <input type="checkbox" v-model="moderationVal" @input="toggleModeration">
        <i class="toogle-switch" />
      </label>
      <span v-else>
        Blocked
      </span>
    </td>
  </tr>
</template>

<script>
import snakecaseKeys from 'snakecase-keys'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

const ALL_MOMENTS_PATH = '/gb/moments'

export default {
  props: {
    row: { type: Object, required: true },
    businessId: { type: String, required: true },
    organizationLinda: { type: Boolean, required: true, default: false },
    hasAdminPrivileges: { type: Boolean, default: false }
  },
  data() {
    return {
      moderationVal: this.row.moderationEnabled
    }
  },
  created() {
    this.allMomentsPath = ALL_MOMENTS_PATH
  },
  computed: {
    employeeEditUrl() {
      return `${window.location.pathname}/${this.row.id}/edit`
    },
    businessesTooltipData() {
      return `<div class='text-left'>${this.row.businesses.size() - 1} allowed</div>`
    }
  },
  methods: {
    toggleModeration(val) {
      const prms = {
        mobileEmployee: {
          moderationEnabled: val.target.checked,
          businesses: this.row.businesses
        }
      }

      return axios.patch(`/gb/ajax/employees/${this.row.id}`, snakecaseKeys(prms))
        .then(() => {
          toastr.success('Employee updated successfully')
        })
        .catch((error) => {
          this.moderationVal = !val.target.checked
          if (error?.response?.status === 403) return
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    },
    buildUrl({ status } = {}) {
      const params = {
        employees: [this.row.id],
        business: this.businessId
      }
      if (status) {
        params.status = status
      }
      return `${this.allMomentsPath}?${qs.stringify(params)}`
    }
  }
}
</script>
