<template>
  <div class="settings-form">
    <div class="settings-form-box pb-2">
      <div class="settings-form-head">
        <h4>Schedule Media</h4>
        <span class="sub">{{ imagesIds.length }}</span>
      </div>
    </div>
    <div class="scroll ver h-100">
      <div class="settings-form-box pb-3">
        <div class="form-field settings-form-field">
          <span class="caption">Category</span>
          <Multiselect
            v-model="selectedCategory"
            :options="categories"
            :allow-empty="false"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            :preselect-first="true"
            track-by="val"
            label="text" />
          <p class="m-0 mt-2">If we can't add media to the selected section, they will be added to the section "Additional"</p>
        </div>

        <LocationSelector class="settings-form-field" @addLocationsTags="addLocationsTags" />

        <div class="form-field-row">
          <div class="form-field days-of-publications mb-3">
            <span class="caption">Days of publications</span>
            <input value="14" min="1" v-model="days"
              type="number"
              name="media-schedule-days-of-publishing">
          </div>
          <div class="form-field w-100 mb-3" @click.stop>
            <span class="caption">Start day</span>
            <div @click.stop>
              <DatePicker class="scheduled"
                ref="startDay"
                min-date="today"
                date-format="d-m-Y"
                :emit-when-create="true"
                v-model="startDay" />
            </div>
          </div>
        </div>

        <div class="form-field">
          <Checkbox v-model="onlyWorkingDays">Only publish on business days</Checkbox>
        </div>
      </div>

      <hr class="m-0">

      <div class="settings-form-box">
        <div class="form-field mb-5">
          <span class="caption">EXIF Settings</span>
          <Radio v-for="( text, key ) in exifOptions" :key="key"
            v-model="exifOption"
            :item="key"
            :id-name="key"
            name="exif_settings"
            @input="changeEXIF">
            {{ text }}
          </Radio>

          <div v-if="exifOption === 'edit'">
            <Multiselect
              v-model="selectedEXIFTemplate"
              :options="exifTemplates"
              :allow-empty="false"
              :close-on-select="true"
              :show-labels="false"
              :preselect-first="true"
              label="title"
              track-by="id" />
            <small class="text-muted">Manage <a href="/exif_templates">EXIF Templates</a></small>
          </div>
        </div>
        <div class="form-field settings-form-field">
          <Checkbox v-model="seoOptimized">Optimize image names for SEO</Checkbox>
          <p class="m-0 mt-2">The names of the images will be changed for better indexing in Google</p>
        </div>
      </div>
    </div>

    <div class="settings-form-control">
      <button class="btn btn-primary-outline rounded" @click="closeScheduledPanel">Cancel</button>
      <button :disabled="sending" :class="['btn btn-primary rounded', {'with-icon': sending}]" @click="submit">
        <i v-if="sending" class="far fa-spinner-third fa-spin" />
        Schedule
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import toastr from 'toastr'
import Multiselect from 'vue-multiselect'
import Checkbox from '../components/checkbox'
import Radio from '../components/radio'
import DatePicker from '../components/datepicker'
import LocationSelector from '../components/location_selector'

export default {
  components: {
    Multiselect, Checkbox, Radio, DatePicker, LocationSelector
  },
  props: {
    imagesIds: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const startDay = new Date()
    startDay.setDate(startDay.getDate() + 1)

    return {
      selectedCategory: '',
      seleltedLocations: [],
      seleltedTags: [],
      days: 14,
      startDay: [startDay],
      onlyWorkingDays: false,
      seoOptimized: true,
      exifOption: 'keep',
      exifOptions: {
        keep: 'Keep Original',
        edit: 'Override with Template',
        clean: 'Clean all EXIF data'
      },
      selectedEXIFTemplate: '',
      exifTemplates: [],
      sending: false
    }
  },
  methods: {
    closeScheduledPanel() {
      this.$emit('closeScheduledPanel')
    },
    async changeEXIF(value) {
      if (value === 'edit') {
        this.exifTemplates = (await axios.get('/api/exif_templates/index_for_select')).data
      }
    },
    addLocationsTags({ locations, tags }) {
      this.seleltedLocations = locations
      this.seleltedTags = tags
    },
    submit() {
      const url = '/api/media_schedules'
      this.sending = true
      axios.post(url, this.collectData())
        .then((res) => {
          Turbolinks.visit(res.data.path)
        })
        .catch((err) => {
          this.sending = false
          if (err?.response?.status === 401) return
          toastr.error(err.response.data.errors)
        })
    },
    collectData() {
      const data = {
        location_tags: this.seleltedTags,
        location_ids: this.seleltedLocations,
        images: this.imagesIds,
        media_schedule: {
          days: this.days,
          start_day: this.startDay[0],
          only_working_days: this.onlyWorkingDays,
          exif_state: this.exifOption,
          category: this.selectedCategory.val,
          fallback_category: true
        }
      }

      if (this.seoOptimized) data.filename_option = 'seoOptimized'
      else data.filename_option = 'keepOriginal'

      if (this.exifOption === 'edit') data.exif_template = this.selectedEXIFTemplate.id

      return data
    }
  }
}
</script>
