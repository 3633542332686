<template>
  <tr :class="['location-row', { disabled: !row.enabled, checked: row.checked }]">
    <td :class="['cell-checkbox column', stateColor()]" column-caption="Location">
      <div class="d-flex align-items-center">
        <AppCheckbox :value="row.checked" @input="onRowCheckToggle" />
        <div class="d-flex flex-column">
          <a :href="locationURL" class="d-inline-block">{{ row.name }}</a>
          <div v-if="row.storeCode">{{ row.storeCode }}</div>
          <div v-if="row.tags.length" class="d-flex flex-wrap gap-2 mt-1">
            <div v-for="tag in row.tags" :key="`${tag}${row.id}`" class="tag">
              <div :class="['tag-text clickable', { active: isTagSelected(tag) }]" @click="addTagToFilter(tag)">
                <span>{{ tag }}</span>
              </div>
            </div>
          </div>
          <div v-if="row.labels.length" class="location-labels">
            <span v-for="label in row.labels" :key="`${label}${row.id}`" class="location-labels-item">
              {{ label }}
            </span>
          </div>
        </div>
      </div>
    </td>
    <td class="column" column-caption="Address"><p class="text-dark m-0">{{ row.address }}</p></td>
    <td column-caption="Keywords">
      <div v-if="row.keywordsCount > 0" class="d-flex flex-column">
        <a class="font-size-18 font-weight-semi-bold text-dark" :href="locationKeywordsURL">{{ row.keywordsCount }}</a>
        <span v-if="row.keywordsSyncedAt" class="text-muted">
          {{ row.keywordsSyncedAt }}
        </span>
      </div>
    </td>
    <td class="column" column-caption="Accounts">
      <div class="accounts-box">
        <div v-if="row.sourceType !== 'API'" class="text-primary font-weight-semi-bold">
          <AppImage
            data-toggle='tooltip'
            title="This location has no active Google Accounts."
            class='mr-2'
            :src="require('new_api_icon.svg')"
            alt="" />
          <span> No API connection </span>
        </div>
        <div v-else class="d-flex flex-wrap gap-2">
          <img :src="require('gmb_api_icon.svg')" alt="">
          <a v-for="account in row.googleAccounts.slice(0, 3)"
            :key="account.id"
            :href="accountURL(account.id)"
            class="accounts-box-item">
            <AppImage
              data-toggle="tooltip"
              :title="account.email"
              class="rounded-circle"
              :src="account.picture"
              alt=""
              :fallback-src="require('no_data/avatar.png')" />
          </a>
          <span v-if="row.googleAccounts.length > 3" class="text-muted small">+{{ row.googleAccounts.length - 3 }}</span>
        </div>
        <div v-if="row.sourceType === 'API'" class="location-context">
          <button @click="getDropdownDirection" class="btn-context" type="button" data-toggle='dropdown'>
            <i class="far fa-ellipsis-h" />
          </button>
          <div class="dropdown-menu" @click.stop role='menu'>
            <div class="location-action">
              <label :class="['switcher', { disabled: isEditingDisabled }]">
                <input
                  ref="enabledCheckbox"
                  type="checkbox"
                  :checked="row.enabled"
                  :disabled="isEditingDisabled"
                  @input="toggleLocation">
                <span class="caption">Enable location</span>
                <i class="toogle-switch" />
              </label>
              <span class="text-muted">
                Disabled locations are not synchronized and not kept up to date.
                For the disabled location, you cannot schedule posts and view insights.
              </span>
            </div>
            <div class="dropdown-divider" />
            <CopyLinkToClipboard
              class="dropdown-item copy-item"
              text-button="Copy Place ID"
              :link="row.placeId" />
            <a :href="locationEditURL" :class="['dropdown-item', { 'disabled-row pointer-events-none': isEditingDisabled }]">Edit data</a>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import AppCheckbox from 'vue_widgets/components/checkbox'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import AppImage from 'vue_widgets/components/image'
import CopyLinkToClipboard from '../../../components/copy_to_clipboard'

export default {
  components: { AppCheckbox, CopyLinkToClipboard, AppImage },
  props: {
    row: { type: Object, required: true },
    orgLocationsPath: { type: String, default: '' },
    gmbLocationsPath: { type: String, default: '' },
    apiOrgLocationsPath: { type: String, default: '' },
    selectedTags: { type: Array, default: () => []}
  },
  created() {
    this.locationURL = `${this.orgLocationsPath}/${this.row.id}`
    // there is no gmb_locations_path, so edit path is still hardcoded
    this.locationEditURL = `gmb/locations/${this.row.remoteLocationId}/edit`
    this.locationKeywordsURL = `${this.locationURL}/keywords`
    this.isEditingDisabled = !Styxie?.USER?.editor
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip()
  },
  methods: {
    onRowCheckToggle(bool) {
      this.$emit('onRowCheckToggle', { bool: bool, locationId: this.row.id })
    },
    accountURL(accoundId) {
      return `/google_accounts/${accoundId}/edit`
    },
    stateColor() {
      const state = this.row.states
      if (state.includes('disabled')) return 'disabled'
      if (state.includes('disconnected')) return 'disconnected'
      if (state.includes('suspended')) return 'suspended'
      return ''
    },
    toggleLocation(e) {
      const bool = e.target.checked
      const action = `${bool ? 'en' : 'dis'}able`
      const apiLocationPath = `${this.apiOrgLocationsPath}/${this.row.id}/${action}`

      axios.post(apiLocationPath)
        .then(() => {
          toastr.success(`Location was ${action}d.`)
          this.$emit('onLocationToggle', { bool: bool, locationId: this.row.id })
        })
        .catch((err) => {
          this.$refs.enabledCheckbox.checked = !bool
          if (err?.response?.status !== 403) {
            const message = err?.response?.data?.text || DEFAULT_ERROR_MESSAGE
            toastr.error(message)
          }
        })
    },
    getDropdownDirection(event) {
      const btn = event.currentTarget
      if (window.innerHeight - btn.getBoundingClientRect().top > 320) {
        btn.nextElementSibling.classList.remove('up')
      } else {
        btn.nextElementSibling.classList.add('up')
      }
    },
    addTagToFilter(tag) {
      this.$emit('addTagToFilter', tag)
    },
    isTagSelected(tag) {
      return this.selectedTags.includes(tag)
    }
  }
}
</script>
