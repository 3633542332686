<template>
  <div class="moment-reviews" ref="reviewsWrapper">
    <div class="moment-reviews__head" :class="{enabled}">
      <CheckboxToggle v-model="enabled">
        Add review to moment
      </CheckboxToggle>
      <span v-if="!enabled">
        You can add reviews from your Google Business account.
      </span>
    </div>
    <div v-if="enabled" class="moment-reviews__wrapper">
      <div class="ml-3 mt-3 btn-group btn-group-toggle" data-toggle="buttons">
        <label :class="['btn btn-xs', { 'btn-primary': reviewKind === REVIEW_KINDS.GMB }]">
          <input type="radio" name="reviewKind" v-model="reviewKind" :value="REVIEW_KINDS.GMB">
          GMB
        </label>
        <label :class="['btn btn-xs', { 'btn-primary': reviewKind === REVIEW_KINDS.FREE_FORM }]">
          <input type="radio" name="reviewKind" v-model="reviewKind" :value="REVIEW_KINDS.FREE_FORM">
          Free Form
        </label>
      </div>

      <template v-if="reviewKind === REVIEW_KINDS.GMB">
        <div class="moment-reviews__filters">
          <div class="moment-reviews__fields">
            <InputSearch v-model="filters.search" class="moment-reviews__field" placeholder="Author name, review text">
              <template #caption><span>Search</span></template>
            </InputSearch>
            <CustomSelect
              v-if="checkinCreatedAt"
              v-model="filters.date"
              class="moment-reviews__field"
              label="Filter by Date"
              :allow-empty="true"
              :values="dateOptions" />
            <CustomSelect v-model="filters.rating"
              class="moment-reviews__field"
              label="Filter by Rating"
              :allow-empty="true"
              :values="ratingOptions" />
          </div>
          <span v-if="gmbReviews.length" class="text-dark">
            Pick one review to show
          </span>
          <hr class="moment-reviews__separator">
          <!-- <CheckboxToggle v-model="filters.withMedia">
            With photo or video
          </CheckboxToggle> -->
        </div>
        <div v-if="gmbReviews.length > 0" class="moment-reviews__list">
          <div v-for="(column, index) in reviewsLayout" class="moment-reviews__column" :key="index">
            <CardReview
              v-for="layoutReview in column"
              :key="layoutReview.remoteId"
              :review="layoutReview"
              :selected="value && value.remoteId === layoutReview.remoteId"
              class="moment-reviews__item"
              @selectReview="$emit('input', value && value.remoteId === layoutReview.remoteId ? null : layoutReview)" />
          </div>
        </div>
        <template v-else-if="anyFiltersApplied">
          <p class="m-0 px-3 pb-3">
            Failed to find any reviews with applied filters
          </p>
        </template>
        <template v-else>
          <p class="m-0 px-3 pb-3 text-warning">
            <i class="far fa-warning" />
            Unfortunately, your business doesn't have any reviews yet.
          </p>
        </template>
        <div v-if="gmbReviews.length > perPage" class="moment-reviews__pagination">
          <hr class="moment-reviews__separator">
          <Pagination
            :page="page"
            :per-page="perPage"
            :total-count="totalReviews"
            @updatePage="updatePage($event, true)" />
        </div>
      </template>

      <FreeFormReview v-else :review="value" @selectFreeFormReview="selectFreeFormReview" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import axiosTransform from 'common/axios'
import camelcaseKeysDeep from 'camelcase-keys-deep'

import CheckboxToggle from 'vue_widgets/components/checkbox_toggle'
import CustomSelect from 'vue_widgets/components/custom_select'
import Pagination from 'vue_widgets/components/pagination'
import InputSearch from 'vue_widgets/components/input_search_ui'
import FreeFormReview from './free_form_review'

import CardReview from './card_review'

const PER_PAGE = 15
const REVIEW_KINDS = {
  GMB: 'GMB',
  FREE_FORM: 'FREE_FORM'
}

export default {
  components: {
    CheckboxToggle,
    InputSearch,
    CustomSelect,
    Pagination,
    CardReview,
    FreeFormReview
  },
  props: {
    value: { type: Object, required: false },
    selectedBusinessId: { type: String, required: true },
    checkinCreatedAt: { type: String, required: false, default: null }
  },
  data() {
    let reviewKind = REVIEW_KINDS.GMB
    if (this.value && !this.value.remoteId) reviewKind = REVIEW_KINDS.FREE_FORM

    return {
      enabled: false,
      totalReviews: 0,
      gmbReviews: [],
      filters: {
        search: '',
        date: '',
        rating: ''
        // withMedia: false
      },
      page: 1,
      reviewKind
    }
  },
  created() {
    this.perPage = PER_PAGE
    this.REVIEW_KINDS = REVIEW_KINDS

    this.dateOptions = [
      { text: 'Off', value: '' },
      { text: 'Newer than the moment', value: 'newer' },
      { text: 'Older than the moment', value: 'older' }
    ]

    this.ratingOptions = [
      { text: 'Off', value: '' },
      { text: 'Allow for 5 star only', value: '5' }
    ]
  },
  computed: {
    query() {
      const nonEmptyFilters = {}
      Object.keys(this.filters).forEach((key) => {
        if (this.filters[key]) {
          nonEmptyFilters[key] = this.filters[key]
        }
      })

      if (this.checkinCreatedAt && nonEmptyFilters.date) {
        nonEmptyFilters.time = this.checkinCreatedAt
      }

      return nonEmptyFilters
    },
    paginatedReviews() {
      const startIndex = (this.page - 1) * this.perPage
      const endIndex = Math.min(startIndex + this.perPage, this.gmbReviews.length)
      return this.gmbReviews.slice(startIndex, endIndex)
    },
    reviewsLayout() {
      const layout = [
        [],
        [],
        [],
        []
      ]

      this.paginatedReviews.forEach((review, index) => {
        layout[index % layout.length].push(review)
      })

      return layout
    },
    anyFiltersApplied() {
      const filterCount = Object.keys(this.query).length
      return filterCount > 0
    }
  },
  methods: {
    updatePage(page, shouldScroll = false) {
      this.page = page
      if (!shouldScroll) return
      this.$nextTick(() => {
        this.$refs.reviewsWrapper?.scrollIntoView()
      })
    },
    fetchReviews() {
      if (!this.enabled) return

      clearTimeout(this.fetchReviewsTimeout)
      this.fetchReviewsTimeout = setTimeout(() => {
        axios.get('/gb/ajax/moments/reviews', {
          params: {
            per_page: this.perPage,
            page: this.currentPage,
            business_id: this.selectedBusinessId,
            ...camelcaseKeysDeep(this.query)
          },
          ...axiosTransform
        })
          .then((response) => {
            this.gmbReviews = response.data
            this.totalReviews = response.data.length

            let newPage = 1
            if (this.value && !this.anyFiltersApplied) {
              const selectedReviewIndex = this.gmbReviews.findIndex((review) => review.remoteId === this.value.remoteId)
              newPage = Math.max(Math.ceil((selectedReviewIndex + 1) / this.perPage), 1)
            }
            this.updatePage(newPage)
          })
      }, 500)
    },
    selectFreeFormReview(freeFormReview) {
      this.$emit('input', freeFormReview)
    }
  },
  mounted() {
    if (this.value && this.value.remoteId) {
      this.fetchReviews()
    }
  },
  watch: {
    enabled: {
      handler(newEnabled) {
        if (newEnabled) {
          this.fetchReviews()
        } else {
          this.$emit('input', null)
        }
      }
    },
    filters: {
      handler() {
        clearTimeout(this.filtersChangeTimeout)

        this.filtersChangeTimeout = setTimeout(() => {
          this.fetchReviews()
        }, 700)
      },
      deep: true
    },
    selectedBusinessId() {
      clearTimeout(this.filtersChangeTimeout)

      this.filtersChangeTimeout = setTimeout(() => {
        this.fetchReviews()
      }, 700)
    },
    value: {
      handler(newValue) {
        if (newValue && !this.enabled) {
          this.enabled = true
        }
      },
      immediate: true
    },
    reviewKind() {
      this.$emit('input', null)
    }
  }
}
</script>

<style lang="scss">
.btn-group-toggle {
  .btn:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    &:hover {
      z-index: 0;
    }
  }

  .btn:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
</style>
