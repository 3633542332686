<template>
  <div class="keyword-show">
    <div class="keyword-show-head">
      <KeywordCommon :keywords-common="keywordsCommon" />
      <div class="keyword-show-control">
        <div class="form-field">
          <Multiselect
            v-model="frequency"
            :options="frequencyStates"
            :searchable="false"
            :close-on-select="true"
            :show-labels="false"
            :allow-empty="false"
            label="label"
            track-by="value"
            @input="changeFrequency" />
        </div>

        <button
          class="btn btn-primary btn-danger rounded with-icon"
          :disabled="isEditingDisabled"
          @click="deleteKeyword(['local', 'organic', 'organic_mobile'])">
          <i class="far fa-trash" />
          Delete
        </button>
      </div>
    </div>
    <div v-if="dataSet.length !== 0" class="keyword-show-chart">
      <span class="chart-title">Historical Keyword Rank {{ dataRange }}</span>
      <KeywordChart :data-set="dataSet" />
    </div>
    <RankTable :keyword="keyword" :keyword-data="keyword.data" />
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import KeywordCommon from './keyword_common'
import KeywordChart from './keyword_chart_show'
import RankTable from './keyword_show_table'
import { names, colors } from './helpers'

export default {
  components: {
    KeywordCommon, KeywordChart, Multiselect, RankTable
  },
  props: {
    keywordsCommon: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      frequency: {},
      frequencyStates: [
        { label: '48 hours', value: 'two_days' },
        { label: 'One week', value: 'one_week' },
        { label: 'Two weeks', value: 'two_weeks' },
        { label: 'One month', value: 'one_month' }
      ],
      minTime: Infinity,
      maxTime: 0
    }
  },
  computed: {
    ...mapGetters(['keyword']),
    dataRange() {
      return `(${moment.utc(this.minTime).format('MMM D, YYYY')} - ${moment.utc(this.maxTime).format('MMM D, YYYY')})`
    }
  },
  created() {
    this.frequency = {
      label: this.frequencyStates.find((f) => f.value === this.keyword.checkFrequency).label,
      value: this.keyword.checkFrequency
    }
    if (this.keyword.data) {
      this.dataSet = Object.keys(this.keyword.data).map((key) => {
        this.minTime = Math.min(this.minTime, this.keyword.data[key][0].x)
        this.maxTime = Math.max(this.maxTime, this.keyword.data[key][this.keyword.data[key].length - 1].x)
        return {
          data: this.keyword.data[key],
          name: names[key],
          color: colors[key]
        }
      })
    } else {
      this.dataSet = []
    }
    this.isEditingDisabled = !Styxie?.USER?.editor
  },
  methods: mapActions(['deleteKeyword', 'changeFrequency'])
}
</script>
