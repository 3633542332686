<template>
  <tr>
    <td column-caption="Name">
      <a :href="`/audit_report_configurations/${row.configurationId}/reports/${row.id}`">{{ row.configurationName }}</a>
    </td>
    <td column-caption="Location" class="column">
      <div v-if="row.location">
        {{ row.location.name }}
        <span v-if="row.location.storeCode" class="d-block font-size-12 text-grey">{{ row.location.storeCode }}</span>
        <span v-if="row.location.address" class="d-block font-size-12 text-muted">{{ row.location.address }}</span>
      </div>
    </td>
    <td column-caption="Keyword">
      {{ row.keyword }}
    </td>
    <td column-caption="Competitors" class="column">
      <div class="d-flex flex-wrap gap-2">
        <div v-for="competitor in row.chosenCompetitors" class="tag">
          <div class="tag-text">{{ competitor }}</div>
        </div>
      </div>
    </td>
    <td column-caption="Created At">
      {{ row.createdAt }}
    </td>
    <td column-caption="Status">
      <div class="d-flex flex-column gap-1">
        <div>
          {{ statuses[row.status] }}
          <i v-if="row.pendingReportSources.length" :id="`report-sources_${row.id}`" class="far fa-exclamation-circle" />
        </div>
        <span v-if="row.pendingReportSources.length">Part {{ collectingDataTotal - row.pendingReportSources.length + 1 }} of {{ collectingDataTotal }}</span>
      </div>
      <b-popover :target="`report-sources_${row.id}`" triggers="hover" placement="bottom">
        <span v-if="row.pendingReportSources.length === 1" class="text-capitalize">{{ row.pendingReportSources[0] }}</span>
        <ul v-else class="m-0 pl-4 py-2">
          <li v-for="source, index in row.pendingReportSources" :key="index" class="text-capitalize">{{ source }}</li>
        </ul>
      </b-popover>
    </td>
    <td class="text-right">
      <button type="button" class="btn btn-link btn-danger" :disabled="isRequested || row.status === 'collecting_data'" @click="onDelete(row.id)">
        <i class="far fa-trash" />
      </button>
    </td>
  </tr>
</template>

<script>
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default {
  props: {
    row: { type: Object, required: true },
    apiBasePath: { type: String, required: true }
  },
  data() {
    return {
      isRequested: false
    }
  },
  created() {
    this.statuses = {
      collecting_data: 'Collecting data',
      failed: 'Failed',
      finished_with_warnings: 'Finished with warnings',
      finished: 'Finished'
    }
  },
  computed: {
    isReportFinished() {
      return this.row.status === 'finished' || this.row.status === 'finished_with_warnings'
    },
    collectingDataTotal() {
      // Counter of audit generation stages. Competitors count + Location + PSI + Competitors list
      // Competitors count + Location + PSI + Competitors list + JSON_LD + zip code + Service areas
      return this.row.chosenCompetitors.length + 6
    }
  },
  methods: {
    onDelete(id) {
      swal({
        text: 'Are you sure? Once deleted, you will not be able to recover this!',
        icon: 'warning',
        buttons: ['Cancel', 'Delete'],
        dangerMode: true
      }).then((result) => {
        if (result) {
          this.isRequested = true
          axios.delete(`${this.apiBasePath}/${id}`).then(() => {
            toastr.success('Audit successfully deleted.')
            this.$emit('reload')
          }).catch((error) => {
            if (error?.response?.status === 403) return
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }).finally(() => {
            this.isRequested = false
          })
        }
      })
    }
  }
}
</script>
