<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <InputSearch v-model.trim="search" custom-classes="form-field-md" />
    </div>

    <VueSlimTable
      ref="table"
      :source="getAccounts"
      :columns="columns"
      class="responsive-table borderless-table m-0 table-of-telephony-accounts">
      <template #row="{ row }">
        <tr>
          <td>
            <a :href="`/call_tracking/telephony_accounts/${row.obfuscated_id}/edit`">{{ row.name }}</a>
          </td>
          <td class="text-capitalize">
            {{ row.provider }}
          </td>
          <td>
            {{ row.sid }}
          </td>
          <td>
            {{ row.sync_finished_at }}
          </td>
          <td class="text-capitalize">
            {{ row.status.split('_').join(' ') }}
          </td>
          <td class="text-end">
            <div class="d-flex align-items-center justify-content-end gap-4 pt-1">
              <a :id="`sync-${row.obfuscated_id}`"
                href="#"
                @click.prevent="syncAccount(row.obfuscated_id)">
                <i class="fa-solid fa-rotate text-primary" />
              </a>
            </div>
            <BTooltip :target="`sync-${row.obfuscated_id}`" title="Sync telephony account" />
          </td>
        </tr>
      </template>
      <template #pagination>
        <Pagination class="pb-0"
          :page="currentPage"
          :per-page="perPage"
          :total-count="totalCount"
          @updatePage="updatePageNumber" />
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import Pagination from 'vue_widgets/components/pagination'
import toastr from 'toastr'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'
import InputSearch from '../../components/input_search_ui'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

export default {
  components: {
    Pagination, InputSearch
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalCount: 0,
      search: '',
      searchTimeout: null
    }
  },
  created() {
    this.columns = [
      { key: 'name', title: 'Friendly Name' },
      { key: 'provider', title: 'Provider' },
      { key: 'sid', title: 'Sid' },
      { key: 'sync_finished_at', title: 'Last synced at' },
      { key: 'status', title: 'Status' },
      { key: 'actions', title: 'Action' }
    ]

    this.channel = subscribe('AuditReportsChannel', ({ data }) => {
      if (data.success) {
        toastr.success(data.message)
      } else {
        toastr.error(data.message)
      }
      this.reload()
    })
  },
  beforeDestroy() {
    removeChannel(this.channel)
  },
  methods: {
    getAccounts() {
      return axios.get(
        '/call_tracking/ajax/telephony_accounts',
        {
          params: {
            search: this.search,
            page: this.currentPage
          },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => {
        this.totalCount = parseInt(res.headers['total-count'], 10)
        return res.data
      })
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.reload()
    },
    reload() {
      this.$refs.table.reload()
    },
    syncAccount(id) {
      axios.post(`/call_tracking/ajax/telephony_accounts/${id}/sync`)
        .catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    }
  },
  watch: {
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.reload()
      }, 500)
    }
  }
}
</script>
