import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import TelephoneAccountsIndex from '../../vue_widgets/jensen/telephony_accounts/index.vue'
import TelephoneAccountsForm from '../../vue_widgets/jensen/telephony_accounts/form.vue'

Styxie.Initializers.JensenTelephonyAccounts = {
  index: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-telephony-account-index'),
        render: (h) => h(TelephoneAccountsIndex, { props: camelCaseKeys(params) })
      })
    })
  },
  new: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-telephony-account-form'),
        render: (h) => h(TelephoneAccountsForm, { props: camelCaseKeys(params) })
      })
    })
  },
  edit: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-telephony-account-form'),
        render: (h) => h(TelephoneAccountsForm, { props: camelCaseKeys(params) })
      })
    })
  }
}
