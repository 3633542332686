<template>
  <tr>
    <td class="text-left text-break w-2">
      <a :href="`/gb/service_titan/jobs/${row.id}`">{{ row.id }}</a>
    </td>
    <td class="text-left text-break w-8">
      <a>{{ row.summary }}</a>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    row: { type: Object, required: true }
  }
}
</script>
