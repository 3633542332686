<template>
  <div class="no-moments">
    <span class="d-block mb-2 text-dark">You don't have any moments yet.</span>
    <a :class="['no-moment__add btn btn-primary gap-3 rounded', { disabled: isEditingDisabled }]" href="/gb/moments/new">
      <i class="far fa-plus fa-fw" />
      <span>Add moment</span>
    </a>
    <div class="no-moments__banner">
      <div class="no-moments__image">
        <img :src="require('mv/mobile_with_plus.svg')" alt="Mobile phone with a plus">
      </div>
      <div class="no-moments__text">
        <h3 class="no-moments__hint">You can create moments via the mobile application:</h3>
        <div class="no-moments__links">
          <a class="no-moments__link" :href="iosAppUrl" target="_blank">
            <img :src="require('mv/Apple.svg')" alt="Apple store">
          </a>
          <a class="no-moments__link" :href="androidAppUrl" target="_blank">
            <img :src="require('mv/Google.svg')" alt="Google play">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    iosAppUrl: String,
    androidAppUrl: String
  },
  data() {
    return {
      isEditingDisabled: !Styxie.USER.editor
    }
  }
}
</script>
