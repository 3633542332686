<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div class="timeline-date">
      <span>{{ review.date }}</span>
    </div>
    <div class="timeline-content">
      <div class="timeline-avatar">
        <img :src="review.reviewer.profile_photo_url" alt="avatar">
      </div>
      <div class="timeline-header">
        <span class="timeline-time">{{ review.time }}</span>
        <span class="timeline-autor">{{ review.reviewer.display_name }}</span>
        <p class="timeline-activity">
          <i v-for="index in 5" :key="index" class="fas fa-star" :class="{ 'star-filled': index <= review.rating }" />
        </p>
        <div class="timeline-summary" v-html="textTohtml(review.comment)" />
      </div>

      <div class="timeline-body" v-if="state === 'show'">
        <div class="timeline-summary" v-html="textTohtml(review.review_reply.comment)" v-if="review.review_reply.comment" />

        <template v-if="!review.deleted && apiConnection">
          <template v-if="review.review_reply.comment && !isEditingDisabled">
            <a href="#" @click.prevent="editReply">Edit Reply</a> |
            <a href="#" @click.prevent="removeReply">Remove Reply</a>
          </template>

          <a href="#" v-else-if="!isEditingDisabled" @click.prevent="editReply">Add Reply</a>
        </template>
      </div>

      <div class="timeline-body" v-if="state === 'edit'">
        <form action="/" @submit.prevent="postReview">
          <b-form-group label='Reply' label-for='reply'>
            <b-form-textarea
              id="reply"
              type="text"
              required
              ref="reply"
              rows="3"
              max-rows="8"
              :value="review.review_reply.comment" />
          </b-form-group>

          <b-button class="btn btn-primary" type="submit">Save</b-button>
          <b-button class="btn btn-default" variant="link" @click="cancelEdit">Cancel</b-button>
        </form>
      </div>
    </div>
  </div>
</template>
<!-- eslint-enable vue/no-v-html -->

<script>
import Vue from 'vue'
import toastr from 'toastr'
import {
  BButton, BForm, BFormGroup, BFormTextarea
} from 'bootstrap-vue'
import axios from 'axios'
import axiosTransform from 'common/axios'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { runSwal } from 'common/delete_with_swal'

Vue.component('BButton', BButton)
Vue.component('BForm', BForm)
Vue.component('BFormGroup', BFormGroup)
Vue.component('BFormTextarea', BFormTextarea)

export default {
  props: {
    review: Object,
    apiConnection: { type: Boolean, required: true }
  },
  data() {
    return {
      state: 'show',
      isEditingDisabled: !Styxie?.USER?.editor
    }
  },
  mounted() {
    this.$$el = $(this.$el)
  },
  methods: {
    editReply() {
      this.state = 'edit'
      this.$nextTick(() => this.$refs.reply.$el.focus())
    },
    postReview() {
      this.$$el.toggleWrapper()
      axios.put(
        `/api${window.location.pathname}/${this.review.remote_id}`,
        {
          gmb_review: {
            review_reply: {
              comment: this.$refs.reply.$el.value
            }
          }
        },
        axiosTransform
      ).then(() => {
        this.review.review_reply.comment = this.$refs.reply.$el.value // eslint-disable-line vue/no-mutating-props
        toastr.success('Reply successfully updated.')
        this.cancelEdit()
      }).catch((error) => {
        if (error?.response?.status === 403) return
        toastr.error(DEFAULT_ERROR_MESSAGE)
      }).finally(() => {
        this.$$el.toggleWrapper({}, false)
      })
    },
    cancelEdit() {
      this.state = 'show'
    },
    removeReply() {
      runSwal(() => {
        this.$$el.toggleWrapper()
        axios.delete(`/api${window.location.pathname}/${this.review.remote_id}/delete_reply`)
          .then(() => {
            delete this.review.review_reply.comment // eslint-disable-line vue/no-mutating-props
            this.$forceUpdate()
          }).catch((error) => {
            if (error?.response?.status === 403) return
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }).finally(() => {
            this.$$el.toggleWrapper({}, false)
          })
      })
    },
    textTohtml(text) {
      return (text || '').replace(/(?:\r\n|\r|\n)/g, '<br />')
    }
  }
}
</script>
