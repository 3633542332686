<template>
  <div>
    <template v-if="hasNoAuditConfigurations">
      <h1 class="h2 color-dark mb-4">Audit Report Configurations</h1>
      <p class="mb-4 color-dark form-size">
        Set up regularly-scheduled reports to see how the locations from your {{ wlcBrand }} account
        perform in their markets. A keyword of your choosing will help us gather a list of local
        competitors you can pick from to serve as the basis of comparison for primary and secondary
        categories, reviews, GBP posts, and media uploads. Click the New Audit button to begin.
      </p>
      <a :href="`${basePath}/new`" :class="['btn btn-primary gap-2 rounded', { disabled: isEditingDisabled }]">
        New Audit
      </a>
    </template>
    <template v-else>
      <div class="d-flex align-items-lg-center justify-content-between flex-column flex-md-row gap-4 mb-4">
        <div>
          <h1 class="h2 m-0 font-weight-semi-bold color-dark">Audit Report Configurations</h1>
        </div>
        <a :href="`${basePath}/new`" :class="['btn btn-primary gap-3 rounded', { disabled: isEditingDisabled }]">
          <i class="far fa-plus-circle fa-fw" />
          New Configuration
        </a>
      </div>

      <FilterComponent :fields="fields" @onFilterChange="onFilterChange" class="mb-4 pb-2" />

      <VueSlimTable
        ref="table"
        :source="getAuditReportConfigurations"
        :columns="columns"
        class="responsive-table borderless-table m-0 table-of-audit-configurations">
        <template #head:actions>
          <div class="text-align-right">Action</div>
        </template>
        <template #row="{ row }">
          <TableRow :row="row" :base-path="basePath" :api-base-path="apiBasePath" @reload="reload" />
        </template>
        <template #pagination>
          <Pagination class="pb-0"
            :page="currentPage"
            :per-page="perPage"
            :total-count="totalCount"
            @updatePage="updatePageNumber" />
        </template>
      </VueSlimTable>
    </template>
  </div>
</template>

<script>
import axiosTransform from 'common/axios'
import Pagination from 'vue_widgets/components/pagination'
import FilterComponent from 'vue_widgets/components/filter'
import { subscribe, removeChannel } from 'vue_widgets/audit_report_configurations/helpers'
import TableRow from './configuration_row'

export default {
  components: { FilterComponent, TableRow, Pagination },
  props: {
    hasNoAuditConfigurations: { type: Boolean, default: true },
    basePath: { type: String, required: true },
    apiBasePath: { type: String, required: true },
    locations: { type: Array, default: () => []}
  },
  data() {
    return {
      currentPage: 1,
      totalCount: 0,
      perPage: 20,

      isEditingDisabled: !Styxie.USER.editor,

      fields: [{
        type: 'search',
        key: 'by_configuration',
        title: 'Audit configuration',
        classes: 'w-100',
        placeholder: 'Type a name of configuration'
      }, {
        type: 'select',
        key: 'by_location',
        title: 'Location',
        classes: 'w-100',
        placeholder: 'All',
        options: this.locations,
        trackBy: 'id',
        label: 'name',
        searchable: true,
        showLabels: false,
        allowEmpty: true,
        multiple: true,
        maxHeight: 540
      }, {
        type: 'search',
        key: 'by_keyword',
        title: 'Keyword search',
        classes: 'w-100',
        placeholder: 'Type a keyword'
      }]
    }
  },
  created() {
    this.wlcBrand = window.Styxie?.WLC.brand
    this.columns = [
      { key: 'name', title: 'Name', orderable: false },
      { key: 'locationName', title: 'Location', orderable: false },
      { key: 'keywords', title: 'Keyword', orderable: false },
      { key: 'competitors', title: 'Competitors', orderable: false },
      { key: 'status', title: 'Status', orderable: false },
      { key: 'schedule', title: 'Schedule', orderable: false },
      { key: 'lastReportCreatedAt', title: 'Last audit', orderable: false },
      { key: 'scheduleAt', title: 'Next audit', orderable: false },
      { key: 'reportsCount', title: 'Audits', orderable: false },
      { key: 'actions', title: 'Action' }
    ]

    this.channel = subscribe('AuditReportsChannel', ({ event }) => {
      if (event === 'audit_finished') {
        toastr.success('Audit successfully completed.')
      }
    })
  },
  beforeDestroy() {
    removeChannel(this.channel)
  },
  methods: {
    getAuditReportConfigurations() {
      return axios.get(this.apiBasePath, {
        params: {
          ...this.filter,
          page: this.currentPage
        },
        paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) },
        ...axiosTransform
      })
        .then((res) => {
          this.totalCount = parseInt(res.headers['total-count'], 10)
          return res.data
        })
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.reload()
    },
    onFilterChange(newFilterValues) {
      this.filter = newFilterValues
      this.currentPage = 1
      this.reload()
    },
    reload() {
      this.$refs.table.reload()
    }
  }
}
</script>
