<template>
  <div class="locations-filter navbar-expand-lg">
    <div class="locations-filter-container">
      <button class="navbar-toggler" type="button" data-toggle="collapse"
        data-target="#filterContent"
        aria-controls="filterContent" aria-expanded="false" aria-label="Toggle navigation">
        <i class="far fa-filter" />
      </button>
      <div class="collapse navbar-collapse" id="filterContent">
        <div class="filters">
          <div class="form-field">
            <span class="caption">States</span>
            <multiselect
              v-model="byState"
              :multiple="true"
              :options="byStateOptions"
              :searchable="true"
              :show-labels="false"
              :max-height="540"
              @input="collectFilter"
              label="label"
              placeholder="Search"
              track-by="value">
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
                <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].label }}</span>
              </template>
            </multiselect>
          </div>
          <div class="form-field">
            <span class="caption">Tags</span>
            <multiselect
              v-model="byTag"
              :multiple="true"
              :options="byTagOptions"
              :searchable="true"
              :show-labels="false"
              :max-height="540"
              @input="collectFilter"
              label="label"
              placeholder="Search"
              track-by="value">
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
                <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].label }}</span>
              </template>
            </multiselect>
          </div>
          <div class="form-field">
            <span class="caption">Folders</span>
            <multiselect
              v-model="bySubAccount"
              :multiple="true"
              :options="bySubAccountOptions"
              :searchable="true"
              :show-labels="false"
              :max-height="540"
              @input="collectFilter"
              label="label"
              placeholder="Search"
              track-by="value">
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
                <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].label }}</span>
              </template>
            </multiselect>
          </div>
          <div class="form-field">
            <span class="caption">Accounts</span>
            <multiselect
              v-model="byAccount"
              :multiple="true"
              :options="byAccountOptions"
              :searchable="true"
              :show-labels="false"
              :max-height="540"
              @input="collectFilter"
              label="label"
              placeholder="Search"
              track-by="value">
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span class="multiselect__single" v-if="values.length > 1 && !isOpen">{{ values.length }} options selected</span>
                <span class="multiselect__single" v-else-if="values.length === 1 && !isOpen">{{ values[0].label }}</span>
              </template>
            </multiselect>
          </div>
          <div class="filters-keys">
            <AppRadio v-for="({label, value}) in disabledOptions"
              v-model="disabledState"
              name="disabled_options"
              :key="value"
              :item="value"
              :id-name="`disabled_options_${value}`">
              {{ label }}
            </AppRadio>
            <CheckboxToggle :value="withKeywords" @input="setCheckboxData($event ,'withKeywords')">
              With keywords
            </CheckboxToggle>
          </div>
        </div>
      </div>
      <div class="search">
        <InputSearch v-model="search" placeholder="Name, Address, Place ID" @input="handleSearchIput">
          <template #caption><span>&nbsp;</span></template>
        </InputSearch>
        <button
          class="btn btn-sm rounded clear-filters"
          v-if="filterChanged"
          @click="reset">
          Clear filters
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import Multiselect from 'vue-multiselect'
import CheckboxToggle from '../../../components/checkbox_toggle'
import AppRadio from '../../../components/radio'
import InputSearch from '../../../components/input_search_ui'

export default {
  components: {
    InputSearch, Multiselect, CheckboxToggle, AppRadio
  },
  props: {
    byStateOptions: Array,
    byTagOptions: Array,
    bySubAccountOptions: Array,
    byAccountOptions: Array,
    initialFilters: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      byState: [],
      byTag: [],
      bySubAccount: [],
      byAccount: [],
      withKeywords: false,
      disabledState: 'all',
      search: '',
      searchTimeout: null
    }
  },
  created() {
    this.brandname = Styxie.WLC.brand
    this.userEmail = Cookies.get('user_email')
    this.selects = [
      'byTag',
      'bySubAccount',
      'byAccount',
      'byState'
    ]
    this.checkboxes = [
      'withKeywords'
    ]

    this.disabledOptions = [
      {
        label: 'All',
        value: 'all'
      },
      {
        label: 'Enabled',
        value: 'enabled'
      },
      {
        label: 'Disabled',
        value: 'disabled'
      }
    ]

    Object.keys(this.initialFilters).forEach((filterKey) => {
      if (Array.isArray(this[`${filterKey}Options`])) {
        this[filterKey] = this[`${filterKey}Options`].filter((option) => this.initialFilters[filterKey].includes(option.value))
      } else {
        this[filterKey] = this.initialFilters[filterKey]
      }
    })

    this.collectFilter()
  },
  computed: {
    filterChanged() {
      return this.selects.find((key) => Boolean(this[key].length)) ||
        this.checkboxes.find((key) => this[key]) ||
        this.search ||
        this.disabledState !== 'all'
    }
  },
  methods: {
    setCheckboxData($event, key) {
      this[key] = $event
      this.collectFilter()
    },
    handleSearchIput() {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(this.collectFilter, 500)
    },
    collectFilter() {
      const filters = {}
      this.selects.forEach((key) => {
        const selectedOptions = this[key].map((option) => option.value)
        if (selectedOptions.length) filters[key] = selectedOptions
      })
      const inputs = ['search', 'disabledState', ...this.checkboxes]
      inputs.forEach((key) => {
        if (this[key]) filters[key] = this[key]
      })
      this.$emit('applyFilter', filters)
    },
    reset() {
      this.selects.forEach((key) => {
        this.$set(this, key, [])
      })
      this.checkboxes.forEach((key) => {
        this.$set(this, key, false)
      })
      this.search = ''
      this.disabledState = 'all'

      this.collectFilter()
    }
  },
  watch: {
    disabledState() {
      this.collectFilter()
    },
    initialFilters(newVal) {
      Object.keys(newVal).forEach((filterKey) => {
        if (Array.isArray(this[`${filterKey}Options`])) {
          this[filterKey] = this[`${filterKey}Options`].filter((option) => newVal[filterKey].includes(option.value))
        } else {
          this[filterKey] = newVal[filterKey]
        }
      })
    }
  }
}
</script>
