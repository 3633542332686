import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import toastr from 'toastr'

import axiosTransform from 'common/axios'
import { runSwal } from '../../common/delete_with_swal'

export default function createStore(prms) {
  return new Vuex.Store({
    state: {
      dataTransfer: null,
      schedule: prms,
      removingImage: false
    },
    actions: {
      deleteSchedule(context, id) {
        runSwal(() => {
          axios.delete(`/api/media_schedules/${id}`)
            .then(() => {
              Turbolinks.visit('/gallery')
            })
            .catch((err) => {
              if (err?.response?.status === 403) return
              toastr.error(err.response.data.errors)
            })
        })
      },
      removeImage({ state, commit }) {
        commit('setRemovingImage', false)
        if (!state.dataTransfer) {
          return
        }
        const { locationId, media } = state.dataTransfer
        commit('updateMediaProperty', {
          mediaId: media.id,
          properties: {
            transfering: true
          }
        })
        axios.delete(`/api/gmb/locations/${locationId}/media/${media.id}`)
          .then(() => {
            commit('removeMediaFromSchedule', media.id)
            commit('updateScheduleProperty', {
              mediaCount: state.schedule.mediaCount - 1
            })
          })
          .catch((err) => {
            commit('updateMediaProperty', {
              mediaId: media.id,
              properties: {
                transfering: false
              }
            })
            toastr.error(err.response?.data?.errors || err.message)
          })
          .finally(() => {
            commit('setDataTransfer', null)
          })
      },
      async replaceImage({ state, commit }, newDate) {
        const { locationId, media } = state.dataTransfer
        commit('updateMediaProperty', {
          mediaId: media.id,
          properties: {
            transfering: true
          }
        })
        commit('addMediaToSchedule', {
          id: `${media.id}_trasfering`,
          scheduledAt: newDate,
          spinner: true,
          locationId: media.locationId
        })

        commit('removeMediaFromSchedule', `${media.id}_trasfering`)

        await axios.put(`/api/gmb/locations/${locationId}/media/${media.id}`, { gmbMedia: { scheduledAt: newDate } }, axiosTransform)
          .then(() => {
            media.scheduledAt = newDate
            commit('removeMediaFromSchedule', media.id)
            commit('addMediaToSchedule', media)
          })
          .catch((err) => {
            commit('updateMediaProperty', {
              mediaId: media.id,
              properties: {
                transfering: false
              }
            })
            toastr.error(err.response?.data?.errors || err.message)
          })
          .finally(() => {
            commit('updateMediaProperty', {
              mediaId: media.id,
              properties: {
                transfering: false
              }
            })
            commit('setDataTransfer', null)
          })
      }
    },
    mutations: {
      setDataTransfer(state, payload) {
        state.dataTransfer = payload
      },
      updateSchedule(state, payload) {
        state.schedule = payload
      },
      updateScheduleProperty(state, payload) {
        Object.keys(payload).forEach((key) => {
          state.schedule[key] = payload[key]
        })
      },
      addMediaToSchedule(state, media) {
        state.schedule.media.push(media)
      },
      removeMediaFromSchedule(state, mediaId) {
        state.schedule.media = state.schedule.media.filter((m) => m.id !== mediaId)
      },
      updateMediaProperty(state, { mediaId, properties }) {
        const mediaIndex = state.schedule.media.findIndex((m) => m.id === mediaId)
        Object.keys(properties).forEach((key) => {
          Vue.set(state.schedule.media[mediaIndex], key, properties[key])
        })
      },
      setRemovingImage(state, payload) {
        $('.js-schedule-day').removeClass('dragover')
        state.removingImage = payload
      }
    }
  })
}
