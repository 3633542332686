<template>
  <div :class="['table-actions d-flex justify-content-between flex-wrap', {sticky: isSticky}]">
    <Checkbox
      :value="checked"
      :part-selected="partSelected.isPartSelected"
      @input="onChecked(false)">
      <template v-if="partSelected.selectedCount === partSelected.length">
        <span class="location-count mr-2">{{ partSelected.captionAll }}</span>
        <button class="btn btn-link" @click.prevent="onChecked(false)">Unselect</button>
      </template>
      <template v-else>
        <span class="location-count mr-2">{{ partSelected.caption }}</span>
        <button class="btn btn-link mr-2" @click.prevent="onChecked(true)">Select All</button>
        <button class="btn btn-link" @click.prevent="onChecked(false)">Unselect</button>
      </template>
    </Checkbox>
    <div :class="['actions', {disabled: actionDisabled}]">
      <DropdownTags @saveTags="saveTags" :tag-options="tags" text="Add tags for all selected locations.">
        Tags
        <i class="far fa-chevron-down icon" />
      </DropdownTags>
      <DropdownAction
        @saveSettings="saveNotificationSettings"
        :actions="notificationActions"
        title="Notifications">
        <template #description>
          <p class="text-muted">
            Bulk edit notification settings for your selected locations. Changes will overwrite current notifications settings.
          </p>
        </template>
      </DropdownAction>
      <DropdownAction
        v-if="filters?.disabledState !== 'disabled'"
        @saveSettings="bulkPost"
        :actions="bulkPostingActions"
        title="Instant Bulk Posting">
        <template #description>
          <p class="text-muted">
            Bulk edit notification settings for your selected locations. Changes will overwrite current notifications settings.
          </p>
        </template>
      </DropdownAction>
      <div :class="['radio-buttons toggle', { 'disabled-row pointer-events-none': isEditingDisabled }]">
        <label :class="['radio-btn', {'active': selectedLocationsEnabled(true)}]" @click="enableChecked">
          Enable
        </label>
        <label :class="['radio-btn', {'active': selectedLocationsEnabled(true)}]" @click="disableChecked">
          Disable
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '../../../components/checkbox'
import DropdownAction from '../../../components/dropdown_action'
import DropdownTags from './dropdown_tags'

export default {
  components: { Checkbox, DropdownAction, DropdownTags },
  props: {
    checked: Boolean,
    locations: Array,
    tagOptions: Array,
    rowsTotalCount: Number,
    partSelected: Object,
    filters: Object
  },
  data() {
    return {
      notificationActions: {
        labels: ['Email', 'SMS'],
        type: 'checkbox',
        actions: [
          { label: 'Name', attr: 'name', data: { email: false, sms: false } },
          { label: 'Category', attr: 'category_id', data: { email: false, sms: false } },
          { label: 'Additional categories', attr: 'additional_categories', data: { email: false, sms: false } },
          { label: 'Service area', attr: 'place_infos', data: { email: false, sms: false } },
          { label: 'Regular hours', attr: 'regular_hours_periods', data: { email: false, sms: false } },
          { label: 'Special hours', attr: 'special_hour_periods', data: { email: false, sms: false } },
          { label: 'Primary phone', attr: 'primary_phone', data: { email: false, sms: false } },
          { label: 'Additional phones', attr: 'additional_phones', data: { email: false, sms: false } },
          { label: 'Website', attr: 'website_uri', data: { email: false, sms: false } },
          { label: 'Description', attr: 'description', data: { email: false, sms: false } },
          { label: 'Opening date', attr: 'opening_date', data: { email: false, sms: false } },
          { label: 'Address', attr: 'gmb_address', data: { email: false, sms: false } },
          { label: 'Store code', attr: 'store_code', data: { email: false, sms: false } },
          { label: 'Reviews', attr: 'reviews', data: { email: false, sms: false } },
          { label: 'Questions', attr: 'questions', data: { email: false, sms: false } },
          { label: 'Customers media', attr: 'customers_media', data: { email: false, sms: false } }
        ]
      },
      bulkPostingActions: {
        type: 'links',
        actions: [
          { label: 'Create media for selected locations', href: '/gmb/multi_location_media/new' },
          { label: 'Create post for selected locations', href: '/gmb/multi_location_posts/new' }
        ]
      },
      isSticky: false,
      isEditingDisabled: !Styxie?.USER?.editor
    }
  },
  computed: {
    actionDisabled() {
      return !this.locations.find((row) => row.checked)
    },
    tags() {
      return this.tagOptions.map((el) => el.value).slice(1)
    }
  },
  mounted() {
    const tableActions = document.querySelector('.table-actions')
    if (tableActions) {
      document.addEventListener('scroll', () => {
        this.isSticky = window.scrollY >= tableActions.offsetTop - 1
      })
    }
  },
  methods: {
    selectedLocationsEnabled(enabled) {
      const checkedLocation = this.locations.filter((location) => location.checked)
      if (!checkedLocation.length) {
        return false
      }
      return !checkedLocation.find((location) => !location.enabled === enabled)
    },
    enableChecked() {
      this.$emit('enableChecked')
    },
    disableChecked() {
      this.$emit('disableChecked')
    },
    onChecked(value) {
      this.$emit('onChecked', value)
    },
    saveTags(tags) {
      this.$emit('saveTags', tags)
    },
    saveNotificationSettings(settings) {
      this.$emit('saveNotificationSettings', settings)
    },
    bulkPost(href) {
      this.$emit('bulkPost', href)
    }
  }
}
</script>
