<template>
  <div class='note-change'>
    <i :class="['far fa-sticky-note', {'not-empty': iconActive}]" />
    <quick-edit
      empty-text='Add note'
      type='textarea'
      :classes="vueQuickEditClasses"
      :show-buttons="false"
      @close='save'
      @show='resize'
      v-model="newNoteText" />
    <i class="far fa-save" id='update-icon' :class='{ show: showSaveIcon }' />
  </div>
</template>

<script>
import QuickEdit from 'vue-quick-edit'
import axios from 'axios'
import toastr from 'toastr'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

Vue.component('QuickEdit', QuickEdit)

export default {
  props: {
    noteText: {
      type: String,
      default: ''
    },
    apiOrgLocationPath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      vueQuickEditClasses: { input: 'textareaClass' },
      newNoteText: '',
      textarea: null,
      iconActive: true,
      showSaveIcon: false
    }
  },
  mounted() {
    this.newNoteText = this.noteText ? this.noteText : ''
    this.iconActive = !!this.newNoteText
  },
  methods: {
    save() {
      const noteWithNoEmptyLines = this.textarea.value.toString().replace(/^\s+|\s+$/g, '')
      this.iconActive = !!noteWithNoEmptyLines
      this.$nextTick(() => {
        axios.patch(this.apiOrgLocationPath, {
          organizations_location: { note: noteWithNoEmptyLines }
        }).then(() => {
          this.newNoteText = noteWithNoEmptyLines
          this.showSaveIcon = true
          setTimeout(() => { this.showSaveIcon = false }, 700)
        }).catch((error) => {
          this.newNoteText = this.noteText ? this.noteText : ''
          if (error?.response?.status === 403) return
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
      })
    },
    resize() {
      this.$nextTick(() => {
        [this.textarea] = $('.textareaClass')
        this.iconActive = !!$('textarea.textareaClass').val()
        this.textarea.style.cssText = `height: ${this.textarea.scrollHeight}px`
        this.textarea.addEventListener('input', this.resize)
      })
    }
  }
}
</script>
