import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { runSwal } from '../../../common/delete_with_swal'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

Vue.use(Vuex)

export default function createStore(data) {
  return new Vuex.Store({
    state: {
      rankTableDisable: false,
      keyword: data.keyword,
      ...data.keywordsCommon
    },
    getters: {
      keyword(state) { return state.keyword },
      rankTableDisable(state) { return state.rankTableDisable },
      domain(state) { return state.keyword.domain }
    },
    actions: {
      deleteKeyword({ state, commit }, types) {
        const url = state.apiKeywordsPath
        runSwal(() => {
          commit('disableRankTable', true)
          axios.delete(url, {
            params: {
              ids: [state.keyword.id],
              types
            }
          })
            .then(() => {
              commit('updateKeywordTypes', { types, bool: false })
              if (!['local', 'organic', 'organicMobile'].find((type) => state.keyword[type])) {
                window.location.assign(`${state.orgLocationPath}/keywords`)
              } else {
                commit('disableRankTable', false)
              }
            }).catch((err) => {
              if (err?.response?.status === 403) return
              toastr.error(DEFAULT_ERROR_MESSAGE)
            })
        })
      },
      addRank({ state, commit }, rankType) {
        const type = rankType === 'organicMobile' ? 'organic_mobile' : rankType
        return axios.patch(`${state.apiOrgLocationPath}/keywords`, {
          ids: [state.keyword.id],
          keyword: {
            types: [type],
            check_frequency: state.keyword.checkFrequency
          }
        }).then(() => {
          commit('updateKeywordTypes', {
            types: [rankType],
            bool: true
          })
        }).catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
      },
      changeFrequency({ state }, { value }) {
        const url = state.apiKeywordsPath
        const frequency = new FormData()
        frequency.append('keyword[check_frequency]', value)
        axios.patch(url, frequency, { params: { ids: [state.keyword.id]} })
          .catch((error) => {
            if (error?.response?.status === 403) return
            toastr.error(DEFAULT_ERROR_MESSAGE)
          })
      }
    },
    mutations: {
      updateKeywordTypes(state, { types, bool }) {
        types.forEach((type) => {
          if (type === 'organic_mobile') type = 'organicMobile'
          state.keyword[type] = bool
        })
      },
      disableRankTable(state, bool) {
        state.rankTableDisable = bool
      }
    }
  })
}
