import ServiceTitanCredentials from '../vue_widgets/service_titan/credentials'

Styxie.Initializers.ApiServiceTitan = {
  show(credentials) {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-service-titan-credentials'),
        render: (h) => h(ServiceTitanCredentials, { props: { credentials: credentials } })
      })
    })
  }
}
