import { ref } from 'vue'
import camelcaseKeys from 'camelcase-keys-deep'

export default function useValidation() {
  const errors = ref({})

  // updateErrors should receive errors in the following format:
  //
  //   {
  //     "type": "validation",
  //     "text": "Unable to save changes due to one or several input fields being invalid.",
  //     "details": {
  //     "ring_through_number": [
  //       "can't be blank"
  //     ]
  //   }
  const updateErrors = (newErrors) => {
    errors.value = camelcaseKeys(newErrors)
  }

  const errorText = (field) => {
    if (!errors.value.details) {
      return null
    }

    const fieldErrors = errors.value.details.value[field]
    if (!fieldErrors || fieldErrors.length === 0) {
      return null
    }
    return fieldErrors.join(', ')
  }

  const hasError = (field) => (errorText(field) ? 'error' : '')

  const cleanError = (...rest) => {
    if (!errors.value.details) {
      return
    }

    rest.forEach((field) => {
      delete errors.value.details[field]
    })
  }

  return {
    updateErrors, hasError, errorText, cleanError, errors
  }
}
