<template>
  <form action="/" @submit.prevent="onSubmit" v-if="state === 'edit'" class="mb-3">
    <b-form-group label='Question' label-for='question'>
      <b-form-textarea
        id="question"
        type="text"
        required
        ref="question"
        rows="3"
        minlength="10"
        maxlength="4096"
        max-rows="8" />
    </b-form-group>
    <b-button class="rounded" variant="primary" type="submit">Submit</b-button>
    <b-button class="rounded" variant="secondary" type="button" @click.prevent="state = 'show'">Cancel</b-button>
  </form>

  <div v-else>
    <b-button :class="['mb-3 gap-3 rounded', { disabled: apiConnection && isEditingDisabled }]" variant="primary" type="button"
      @click.prevent="state = 'edit'"
      v-if="questions.length">
      <i class="far fa-circle-plus fa-fw" />
      Add Question
    </b-button>
    <a :href="`${pathname}/bulk_qna`" variant="link" :class="['btn mb-3 gap-3 rounded', { disabled: apiConnection && isEditingDisabled }]"
      v-if="questions.length">
      <i class="far fa-arrow-up-from-square fa-fw" />
      Bulk Upload
    </a>
    <div class="text-center text-muted mb-6" v-else-if="apiConnection && !isEditingDisabled">
      If you want to add questions, just click
      <a href="#" class="alert-info p-1" @click.prevent="state = 'edit'"> Add Question</a>
      or
      <a :href="`${pathname}/bulk_qna`" class="alert-info p-1"> Upload CSV</a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      state: 'show',
      pathname: window.location.pathname,
      isEditingDisabled: !Styxie.USER.editor
    }
  },
  computed: mapState(['questions', 'apiConnection']),
  methods: {
    ...mapActions(['postQuestion']),
    onSubmit() {
      const $el = $(this.$el).toggleWrapper()
      this.postQuestion({
        text: this.$refs.question.$el.value,
        cleanWrapperCb: (bool = true) => {
          $el.toggleWrapper({}, false)
          if (bool) this.state = 'show'
        }
      })
    }
  }
}
</script>
