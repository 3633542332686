<template>
  <tr>
    <td class="align-top" column-caption="Configuration name">
      <a :href="`/reports/configurations/${row.id}/edit`" class='mb-sm-2 d-block'>{{ row.title }}</a>
    </td>
    <td class="align-top" column-caption="White Labeling">
      {{ row.wlcTitle }}
    </td>
    <td class="column align-top" column-caption="Locations">
      <div class="d-flex flex-column gap-6 color-dark">
        <div v-for="({ name, storeCode, address, locationType }, index) in row.locations.items" :key="index" class="d-flex flex-column gap-1">
          <strong>{{ name }}</strong>
          <span v-if="storeCode" class="text-muted font-size-14">{{ storeCode }}</span>
          <span v-if="locationType === 'sab'">Service Area Business</span>
          <span v-else-if="address" class="font-size-14">{{ address }}</span>
        </div>
        <span v-if="row.locationTags.length !== 0">
          Locations by Tags:
          <span v-for="tag in row.locationTags" class="tag">
            <span class='tag-text'>{{ tag }}</span>
          </span>
        </span>
      </div>
    </td>
    <td class="align-top" column-caption="Created At">
      {{ row.createdAt }}
    </td>
    <td class="align-top" column-caption="Last Run">
      {{ row.lastRun }}
    </td>
    <td class="align-top" column-caption="Nexy Run">
      {{ row.nextRun }}
    </td>
    <td class="align-top" column-caption="Schedule">
      {{ row.schedule }}
    </td>
    <td class="align-top" column-caption="Reports">
      <template v-if="typeof row.reportsLink === 'string'">
        <span class="text-muted">{{ row.reportsLink }}</span>
      </template>
      <a v-else :href="`/reports?by_configuration=${row.id}`"> {{ row.reportsLink.title }} </a>
    </td>
    <td class="actions align-top">
      <div class="d-flex justify-content-end gap-2">
        <template v-if="row.locations.count === 0 && row.locationTags.count === 0">
          <i class="far fa-circle-exclamation" :id="`no-location_${row.id}`" />
          <BTooltip :target="`no-location_${row.id}`" :title="translations.noLocations" />
        </template>
        <a v-else href="#" @click.prevent="runGenerateReport">
          <i v-if="!processingRequest" class="far fa-play" :id="`report-run_${row.id}`" />
          <i v-else class="far fa-spinner-third fa-spin" />
          <BTooltip :target="`report-run_${row.id}`" :title="translations.generateReportNow" />
        </a>

        <a v-if="canRemoveConfiguration" href="#" @click.prevent="removeReport">
          <i class="far fa-trash text-danger" :id="`report-delete_${row.id}`" />
          <BTooltip :target="`report-delete_${row.id}`" :title="translations.removeConfiguration" />
        </a>
      </div>
    </td>
  </tr>
</template>

<script>
import { runSwal } from 'common/delete_with_swal'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default {
  props: {
    row: { type: Object, required: true },
    reportConfigurationsUrl: { type: String, required: false },
    canRemoveConfiguration: { type: Boolean, required: false },
    apiReportConfigurationsUrl: { type: String, required: false }
  },
  data() {
    return {
      processingRequest: false
    }
  },
  methods: {
    runGenerateReport() {
      if (this.processingRequest) return

      const url = `${this.apiReportConfigurationsUrl}/${this.row.id}/run`
      this.processingRequest = true

      axios.post(url)
        .then((_response) => {
          toastr.success('Report generation is started')
        })
        .catch((error) => {
          if (error.response.status === 422) {
            toastr.warning(error.response.data.msg, { timeOut: 0 })
          } else {
            if (error?.response?.status === 403) return
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        })
        .finally(() => {
          this.processingRequest = false
        })
    },
    removeReport() {
      runSwal(() => {
        const url = `${this.apiReportConfigurationsUrl}/${this.row.id}`

        axios.delete(url)
          .then((res) => {
            this.$emit('reportRemoved', res.data.msg)
          })
          .catch((error) => {
            if (error?.response?.status === 403) return
            toastr.error(DEFAULT_ERROR_MESSAGE)
          })
      })
    }
  },
  mounted() {
    const icons = this.$el.querySelectorAll("i[data-toggle='tooltip']")

    icons.forEach((icon) => $(icon).tooltip())
  },
  created() {
    this.translations = {
      noLocations: 'Report Configuration does not have any locations, so its impossible to generate a report.',
      generateReportNow: 'Generate Report Now',
      removeConfiguration: 'Remove Configuration'
    }
  }
}
</script>
