<template>
  <li class="answer">
    <div class="answer-header">
      <img class="rounded-circle mr-2" height="30" width="30"
        alt="avatar"
        :src="answer.author.profile_photo_url" v-if="answer.author.profile_photo_url">

      <span class="answer-author">
        <strong>{{ answer.author.display_name }}</strong>
        <small>{{ answer.remote_created_at }}</small>
      </span>

      <div class="answer-actions">
        <i class="far fa-thumbs-up" /> {{ answer.upvote_count }}
      </div>
    </div>

    <div class="answer-body" v-if="state === 'show'">
      {{ answer.text }}
    </div>

    <div class="answer-body" v-if="state === 'edit'">
      <answerForm
        ref="form"
        :answer="answer"
        @onCancel="cancelForm" />
    </div>

    <div
      v-if="answer.author.type === 'MERCHANT' && state === 'show' && !currentQuestion.remote_deleted_at && !isEditingDisabled"
      class="answer-form mt-3">
      <b-button href="#" :class="['btn', { disabled: !apiConnection }]" variant="primary" @click.prevent="state = 'edit'">Edit</b-button>
      <b-button href="#" :class="['btn', { disabled: !apiConnection }]" variant="danger" @click.prevent="onDeleteAnswer">Delete</b-button>
    </div>
  </li>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { runSwal } from 'common/delete_with_swal'
import AnswerForm from './answer_form'

export default {
  props: {
    answer: Object
  },
  data() {
    return {
      state: 'show',
      isEditingDisabled: !Styxie?.USER?.editor
    }
  },
  computed: mapState(['currentQuestion', 'apiConnection']),
  methods: {
    ...mapActions(['deleteAnswer']),
    onDeleteAnswer() {
      runSwal(() => {
        const $el = $(this.$el).toggleWrapper()
        this.deleteAnswer({
          question: this.currentQuestion,
          answer: this.answer,
          cleanWrapperCb: () => {
            $el.toggleWrapper({}, false)
          }
        })
      })
    },
    cancelForm() {
      this.state = 'show'
    }
  },
  watch: {
    'answer.text'() {
      this.state = 'show'
    }
  },
  components: { AnswerForm }
}
</script>

<style scoped lang="scss">
  .answer {
    background: #f5f5f5;
    border-radius: 3px;
    list-style: none;
    margin-bottom: 10px;
    padding: 10px;

    &-author {
      line-height: 30px;
    }

    &-body {
      margin-left: 7px;
      margin-top: 7px;
    }

    &-actions {
      float: right;
      font-size: 15px;
    }
  }
</style>
