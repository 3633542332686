<template>
  <div>
    <div class="form-field mb-4">
      <label class="mb-0">
        Telephony Provider
      </label>
      <Multiselect
        v-model="selectedProvider"
        :multiple="false"
        :options="providers"
        :searchable="false"
        :show-labels="false"
        :max-height="540"
        class="text-capitalize">
        <template slot="option" slot-scope="props">
          <div>
            <span class="text-capitalize">{{ props.option }}</span>
          </div>
        </template>
      </Multiselect>
      <div v-if="hasError('provider')">
        <small class="text-danger">{{ errorText('provider') }}</small>
      </div>
    </div>
    <div class="form-field mb-4">
      <label class="mb-0">
        {{ accountSidLabelSet }}
      </label>
      <input v-model="sid">
      <div v-if="hasError('sid')">
        <small class="text-danger">{{ errorText('sid') }}</small>
      </div>
    </div>
    <div class="form-field mb-4">
      <label class="mb-0">
        Token
      </label>
      <input v-model="authToken">
      <div v-if="hasError('authToken')">
        <small class="text-danger">{{ errorText('authToken') }}</small>
      </div>
    </div>
    <div
      v-if="selectedProvider === 'signalwire'"
      class="form-field mb-4">
      <label class="mb-0">
        Space URL
      </label>
      <input v-model="spaceUrl">
      <div v-if="hasError('spaceUrl')">
        <small class="text-danger">{{ errorText('spaceUrl') }}</small>
      </div>
    </div>
    <div
      v-if="selectedProvider === 'signalwire'"
      class="form-field mb-4">
      <label class="mb-0">
        Signing key
      </label>
      <input v-model="signingKey">
      <div v-if="hasError('signingKey')">
        <small class="text-danger">{{ errorText('signingKey') }}</small>
      </div>
    </div>

    <div class="mv-grid">
      <div class="narrow-container d-flex pt-5 pb-6">
        <button
          v-if="isNewAccount()"
          class="btn btn-primary rounded mr-3"
          type="button"
          @click.prevent="addAccount">
          Add Account
        </button>

        <button
          v-else
          class="btn btn-primary rounded mr-3"
          type="button"
          @click.prevent="updateAccount">
          Update Account
        </button>

        <a
          class="btn rounded"
          href="/call_tracking/telephony_accounts">
          Cancel
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import toastr from 'toastr'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'
import useValidation from '../../../common/jensen/validation'

export default {
  components: { Multiselect },
  props: {
    telephonyAccount: Object
  },
  data() {
    const validation = useValidation()
    return {
      sid: this.isNewAccount() ? '' : this.telephonyAccount.sid,
      accountSidLabel: '',
      authToken: this.isNewAccount() ? '' : this.telephonyAccount.authToken,
      spaceUrl: this.isNewAccount() ? '' : this.telephonyAccount.spaceUrl,
      signingKey: this.isNewAccount() ? '' : this.telephonyAccount.signingKey,
      selectedProvider: this.isNewAccount() ? 'twilio' : this.telephonyAccount.provider,
      providers: ['twilio', 'signalwire'],
      updateErrors: validation.updateErrors,
      errorText: validation.errorText,
      hasError: validation.hasError
    }
  },
  computed: {
    accountSidLabelSet() {
      return this.selectedProvider === 'signalwire' ? 'Project ID' : 'Account SID'
    }
  },
  methods: {
    isNewAccount() {
      return !this.telephonyAccount
    },
    collectParams() {
      return {
        sid: this.sid,
        auth_token: this.authToken,
        space_url: this.spaceUrl,
        provider: this.selectedProvider,
        signing_key: this.signingKey
      }
    },
    async addAccount() {
      const params = this.collectParams()

      axios.post(
        '/call_tracking/ajax/telephony_accounts',
        params
      ).then(() => {
        $(document).one('turbolinks:load', () => {
          toastr.success('Telephony account added successfully!')
        })
        Turbolinks.visit('/call_tracking/telephony_accounts')
      }).catch((err) => {
        this.updateErrors(err.response?.data)
        toastr.error(err.response?.data?.text || DEFAULT_ERROR_MESSAGE)
      })
    },
    async updateAccount() {
      const params = this.collectParams()

      axios.patch(
        `/call_tracking/ajax/telephony_accounts/${this.telephonyAccount.obfuscatedId}`,
        params
      ).then(() => {
        $(document).one('turbolinks:load', () => {
          toastr.success('The telephony account has been successfully updated!')
        })
        Turbolinks.visit('/call_tracking/telephony_accounts')
      }).catch((err) => {
        this.updateErrors(err.response?.data)
        toastr.error(err.response?.data?.error || DEFAULT_ERROR_MESSAGE)
      })
    }
  }
}
</script>
