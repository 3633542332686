<template>
  <div class="business-logo-control">
    <h5 class="h5 color-dark font-weight-semi-bold">Business logo</h5>
    <span v-if="error" class="text-danger">{{ error }}</span>

    <div v-if="internalLogo" class="d-flex flex-row gap-4 align-items-center">
      <div class="w-50">
        <img :src="internalLogo" alt="logo" class="img-fluid mb-2">

        <div>
          <label for="placement">Placement</label>
          <Multiselect
            id="placement"
            :options="PLACEMENTS"
            :searchable="false"
            :close-on-select="true"
            :value="internalPlacement"
            :disabled="isSubmitting"
            deselect-label=""
            @select="onChangePlacement"
            placeholder="Pick a state!">
            <template slot="singleLabel" slot-scope="{ option }">
              <span :title="option.text" class="d-flex align-items-center">
                <img :src="require(`logo_placement/${option.icon}`)" width="24" height="20"
                  class="mr-2"
                  alt="">
                {{ option.text }}
              </span>
            </template>

            <template slot="option" slot-scope="{ option }">
              <span :title="option.text" class="d-flex align-items-center">
                <img :src="require(`logo_placement/${option.icon}`)" width="24" height="20"
                  class="mr-2"
                  alt="">
                {{ option.text }}
              </span>
            </template>
          </Multiselect>
        </div>

      </div>

      <div>
        <button type="button" :class="['btn btn-outline-primary d-block mb-3 position-relative', { disabled: isSubmitting }]">
          <input
            type="file"
            @change="onFileChange"
            class="business-logo-control__add-btn"
            accept=".png">
          <i class="far fa-fw fa-download mr-2" />
          Upload new
        </button>

        <button
          type="button"
          @click.prevent="onLogoRemove"
          :class="['btn btn-outline-danger d-block', { disabled: isSubmitting }]">
          <i class="far fa-fw fa-trash  mr-2" />
          Delete logo
        </button>
      </div>
    </div>

    <div v-else class="d-flex flex-column align-items-center text-center">
      <button type="button" class="btn btn-xl btn-primary d-block position-relative my-3">
        <input
          type="file"
          @change="onFileChange"
          :class="['business-logo-control__add-btn', { disabled: isSubmitting }]"
          accept=".png">
        <i class="far fa-image fa-fw mr-2" />
        Add logo
      </button>

      <p class="mb-0">Upload logo in PNG format with a transparent background.</p>
      <p class="mb-0">The image size should not exceed 1024 pixels on the longest side.</p>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axiosTransform from 'common/axios'

const PLACEMENTS = [
  { text: 'Center', value: 'center', icon: 'center.svg' },
  { text: 'Top left corner', value: 'top_left', icon: 'top_left.svg' },
  { text: 'Top right corner', value: 'top_right', icon: 'top_right.svg' },
  { text: 'Bottom left corner', value: 'bottom_left', icon: 'bottom_left.svg' },
  { text: 'Bottom right corner', value: 'bottom_right', icon: 'bottom_right.svg' },
  { text: 'Nowhere', value: 'nowhere', icon: 'nowhere.svg' }
]

const findPacement = (value) => PLACEMENTS.find((placement) => value === placement.value)

export default {
  components: {
    Multiselect
  },
  props: {
    businessId: { type: String, required: true },
    checkinImageLogoPlacement: { type: String, required: true },
    logo: { type: String }
  },
  data() {
    return {
      internalLogo: this.logo,
      internalPlacement: findPacement(this.checkinImageLogoPlacement),
      error: null,
      isSubmitting: false
    }
  },
  methods: {
    onFileChange(e) {
      if (e.target.files.length) {
        const data = new FormData()
        data.append('mobile_business[logo]', e.target.files[0])
        this.updateBusiness(data)
          .catch((err) => {
            if (err.response.data.type === 'validation' && err.response.data.errors.logo.length) {
              this.error = err.response.data.errors.logo.join(', ')
            }
            e.target.value = null
          })
      }
    },
    onLogoRemove() {
      const data = new FormData()
      data.append('mobile_business[remove_logo]', true)
      this.updateBusiness(data)
    },
    onChangePlacement(selectedOption) {
      const data = new FormData()
      data.append('mobile_business[checkin_image_logo_placement]', selectedOption.value)

      this.updateBusiness(data)
        .then(() => {
          this.internalPlacement = selectedOption
        })
    },
    updateBusiness(data) {
      this.isSubmitting = true
      return axios.put(`/gb/ajax/businesses/${this.businessId}`, data, { transformResponse: axiosTransform.transformResponse })
        .then((res) => {
          this.internalLogo = res.data.logo
          this.internalPlacement = findPacement(res.data.checkinImageLogoPlacement)
        })
        .finally(() => {
          this.isSubmitting = false
        })
    }
  },
  created() {
    this.PLACEMENTS = PLACEMENTS
  }
}
</script>
